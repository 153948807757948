import React, {useState} from "react";
import {useStateValue} from "../../components/StateProvider";
import {fetchTournament, getDetailData} from "../../api/RestService";
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonIcon,
    IonItem,
    IonPage,
    IonRow,
    IonTitle,
    IonInfiniteScroll
} from "@ionic/react";
import {TeamLogo} from "../../components/organization/TeamLogoLinked";
import {IdPageProps} from "../MatchPage";
import Header from "../../components/Header";

import VodPlayer from "../../components/VodPlayer";
import GroupStage from "../../components/tournament/stage/GroupStage";
import {PlayoffStage} from "../../components/tournament/stage/PlayoffStage";
import './TournamentPage.css'
import {formatUnixTimeStamp, formatUnixTimeStampInMillis} from "../../utils/DateUtils";
import {RegionFlag} from "../../components/RegionFlag";
import {normalizeEnumString} from "../../utils/StringUtils";
import {DoubleEliminationPlayoff} from "../../components/tournament/stage/DoubleEliminationPlayoff";
import {Link} from "react-router-dom";


import {setDescription, setTabTitle, VOD_PLAYER} from "../../App";
import {playOutline} from "ionicons/icons";
import {LiquipediaButton} from "../../components/LiquipediaButton";
import {TournamentTabBar} from "../../components/tournament/TournamentTabBar";
import {NotFoundPage} from "../NotFoundPage";

import "handy-scroll/dist/handy-scroll.css";
import {QualifierPlayoff} from "../../components/tournament/stage/QualifierPlayoff";

const handyScroll = require("handy-scroll");

export const TournamentPage: React.FC<IdPageProps> = ({match}) => {

    // @ts-ignore
    const [context, dispatch] = useStateValue();

    const [tournamentData, setTournamentData] = useState<any>();
    let groupStages = [];

    if (!isNaN(Number(match.params.id))) {
        if (!tournamentData || String(tournamentData.id) !== match.params.id) {
            fetchTournament(Number(match.params.id), data => {
                    setTournamentData(data);
                    setTabTitle(data.name);
                    setDescription(getTournamentDescription(data));
                    readyScroll("scroll" + data.id);
                }
            );
        } else {
            groupStages = getGroupStages(tournamentData);
        }
    }

    if (tournamentData && tournamentData.error) {
        return <NotFoundPage/>
    }


    return (
        <IonPage>
            {/*<Header/>*/}
            <IonContent>
                <IonGrid className={"no-padding"}>
                    {/* To jump between multiple sibling tournaments */}
                    {tournamentData && generateTabBarForSubTournaments(tournamentData)}
                    <IonItem color="darkblue center"><IonTitle
                        className="ion-text-center">{tournamentData && tournamentData.name}</IonTitle>
                        {/* Tournament Button for Liquipedia Link */}
                        <LiquipediaButton vods={tournamentData}/>
                    </IonItem>
                    {/* Header Information of tournament */}
                    <IonItem>
                        <div className={"tournament-page-header"}>
                            <div className={"tournament-page-header-container"}>

                                <table>
                                    <tbody>
                                    <tr>
                                        <td className={"header-info-item"}>
                                            <p className={"tournament-page-info-headline"}>Date</p>
                                            <h4>{tournamentData && getFormattedTournamentDate(tournamentData)}</h4>
                                        </td>
                                        <td className={"header-info-item"}>
                                            <p className={"tournament-page-info-headline"}>Organizer</p>
                                            <h4>{tournamentData && getDetailData(tournamentData, "OWNER")}</h4>
                                        </td>
                                        <td className={"header-info-item"}>
                                            <p className={"tournament-page-info-headline"}>Prize Pool</p>
                                            <h4>{tournamentData && getDetailData(tournamentData, "PRIZE_POOL")}</h4>
                                        </td>
                                        <td className={"header-info-item"}>
                                            <p className={"tournament-page-info-headline"}>Type</p>
                                            <h4>{tournamentData && getDetailData(tournamentData, "TOURNAMENT_TYPE")}</h4>
                                        </td>
                                        <td className={"header-info-item"}>
                                            <p className={"tournament-page-info-headline"}>Region</p>
                                            <h4>
                                                {tournamentData &&
                                                <RegionFlag country={normalizeEnumString(tournamentData.region)}
                                                            width={25}/>}
                                                <span className={"small-spacer"}></span>
                                                {tournamentData && normalizeEnumString(tournamentData.region)}
                                            </h4>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </IonItem>
                    {/* List of teams */}
                    <IonItem color="darkblue center">
                        <IonTitle className="ion-text-center">Teams</IonTitle>
                    </IonItem>

                    {
                        tournamentData &&
                        <IonItem>
                            <div className={"tournament-team-div"}>
                                {
                                    tournamentData.teams.map((team: any) => {
                                        return (<div className={"team-list-div"} key={team.id + "TeamEntry"}>
                                            <Link className="float-right no-underline-link underline-hover"
                                                  to={"/team/" + team.id}>
                                                <TeamLogo teamId={team.id} height={50}></TeamLogo>
                                                <p className={"color-contrast"}>{team.name}</p>
                                            </Link>
                                        </div>);
                                    })
                                }
                            </div>
                        </IonItem>
                    }

                    {/* Group stages headline */}
                    {
                        tournamentData && groupStages.length > 0 ? <React.Fragment>
                            <IonItem color="darkblue center">
                                <IonTitle className="ion-text-center">Group Stages</IonTitle>
                            </IonItem>
                        </React.Fragment> : ""
                    }

                    {/* Group stages */}
                    <IonRow className={"no-padding"}>
                        {tournamentData && getGroupStage(tournamentData, groupStages)}
                    </IonRow>

                    {/* Playoff stages */}


                    {
                        tournamentData &&
                        getQualifierEliminationStages(tournamentData).map((stage) => {
                            return (
                                <React.Fragment key={stage.id}>
                                    <IonItem color="darkblue center">
                                        <IonTitle className="ion-text-center">{stage.name}</IonTitle>
                                    </IonItem>
                                    <div id={"scroll" + (tournamentData ? stage.id : "")}
                                         className={"scroll"}>
                                        <div className={"scroll-inner"}>
                                            <IonRow key={stage.id + "SingleEl"} className={"no-padding"}>
                                                <IonCol className={"no-padding"}>
                                                    <IonItem>
                                                        <div className="tournament-container">
                                                            <QualifierPlayoff
                                                                id={stage.id}/>
                                                        </div>
                                                    </IonItem>
                                                </IonCol>
                                            </IonRow>

                                        </div>

                                    </div>
                                </React.Fragment>)
                        })
                    }

                    {
                        tournamentData &&
                        getSingleEliminationStages(tournamentData).map((stage) => {
                            return (
                                <React.Fragment key={stage.id}>
                                    <IonItem color="darkblue center">
                                        <IonTitle className="ion-text-center">{stage.name}</IonTitle>
                                    </IonItem>
                                    <div id={"scroll" + (tournamentData ? stage.id : "")}
                                         className={"scroll"}>
                                        <div className={"scroll-inner"}>
                                            <IonRow key={stage.id + "SingleEl"} className={"no-padding"}>
                                                <IonCol className={"no-padding"}>
                                                    <IonItem>
                                                        <div className="tournament-container">
                                                            <DoubleEliminationPlayoff
                                                                id={getGrandFinalStageInElimination(stage).id}/>
                                                        </div>
                                                    </IonItem>
                                                </IonCol>
                                            </IonRow>
                                        </div>

                                    </div>
                                </React.Fragment>)
                        })
                    }

                    {
                        tournamentData &&
                        getDoubleEliminationStages(tournamentData).map((stage) => {
                            return (
                                <React.Fragment>
                                    <IonItem color="darkblue center">
                                        <IonTitle className="ion-text-center">{stage.name}</IonTitle>
                                    </IonItem>
                                    <div id={"scroll" + (tournamentData ? stage.id : "")}
                                         className={"scroll"}>
                                        <div className={"scroll-inner"}>
                                            <IonRow key={stage.id + "DoubleEl"} className={"no-padding"}>
                                                <IonCol className={"no-padding"}>
                                                    <IonItem>
                                                        <div className="tournament-container">
                                                            <DoubleEliminationPlayoff
                                                                id={getGrandFinalStageInElimination(stage).id}/>
                                                        </div>
                                                    </IonItem>
                                                </IonCol>
                                            </IonRow>
                                        </div>

                                    </div>
                                </React.Fragment>)
                        })
                    }


                    {/*tournamentData && (!isMultiBracketTournament(tournamentData) ?
                        <IonRow className={"no-padding"}>
                            {getGroupStage(groupStages, tournamentData)}
                            <IonCol className={"no-padding"}>
                                <div className="tournament-container">
                                    <div className="tournament-headers">
                                        {
                                            tournamentData && getPlayoffStages(tournamentData).map((stage: any) => {
                                                return (
                                                    <IonItem key={stage.id + "heading"}
                                                             className={"tournament-header-label"}
                                                             color="darkblue"><IonTitle
                                                        className="ion-text-center">{stage.name}</IonTitle></IonItem>)
                                            })
                                        }
                                    </div>
                                    {tournamentData && <div className="tournament-brackets">
                                        {getTournamentPlayoffStages(tournamentData)}
                                    </div>}
                                </div>
                            </IonCol>
                        </IonRow> :
                        <React.Fragment> {
                            getPlayoffStages(tournamentData).map((parentStages: any) => {
                                return (
                                    <IonRow key={parentStages.id + "ParentStage"} className={"no-padding"}>
                                        <IonCol className={"no-padding"}>
                                            <IonItem key={parentStages.id + "heading"}
                                                     className={"tournament-header-label"}
                                                     color="darkblue"><IonTitle
                                                className="ion-text-center">{parentStages.name}</IonTitle></IonItem>
                                            <div className="tournament-container">
                                                <div className="tournament-headers">
                                                    {
                                                        tournamentData && parentStages.childrenStages.map((stage: any) => {
                                                            return (
                                                                <IonItem key={stage.id + "heading"}
                                                                         className={"tournament-header-label"}
                                                                         color="darkblue"><IonTitle
                                                                    className="ion-text-center">{stage.name}</IonTitle></IonItem>)
                                                        })
                                                    }
                                                </div>
                                                {tournamentData && <div className="tournament-brackets">
                                                    {getFormattedTournamentPlayoffStages(parentStages.childrenStages)}
                                                </div>}
                                            </div>
                                        </IonCol>
                                    </IonRow>
                                )
                            })
                        }</React.Fragment>)
                    */}
                </IonGrid>
            </IonContent>
            {/*VOD_PLAYER*/}
        </IonPage>
    );
};


function readyScroll(id: string) {
    let widthHeight = -1;
    let noUpdate = -1;

    //Watch container for changes
    let interval = setInterval(() => {
        let element = document.getElementById(id);

        if (element != null) {
            //Has height and width not changed? I know in some cases this might result in the same value even though they changed but that'd require one of them to shrink which does not happen
            if (widthHeight === element.offsetWidth + element.offsetHeight) {
                noUpdate++;
            } else {
                widthHeight = element.offsetWidth + element.offsetHeight;

                if (!handyScroll.default.mounted(element)) {
                    handyScroll.default.mount(element);
                }

                handyScroll.default.update(element);
            }

            if (noUpdate > 10) {
                clearInterval(interval);
            }
        }
    }, 500);
}

function getTournamentDescription(tournamentData: any) {
    let desc = tournamentData.name;
    desc = 'All about and watch/stream the tournament ' + desc;
    desc += ' on ' + getFormattedTournamentDate(tournamentData);
    return desc;
}


export function getFormattedTournamentDate(tournamentData: any) {
    return (formatUnixTimeStampInMillis(parseInt(getDetailData(tournamentData, "START_DATE"))).toLocaleDateString() + " - " + formatUnixTimeStampInMillis(parseInt(getDetailData(tournamentData, "END_DATE"))).toLocaleDateString());
}

function generateTabBarForSubTournaments(tournamentData: any) {
    if (!tournamentData.parentTournament) {
        return "";
    } else {
        return (<IonRow>
            <TournamentTabBar id={tournamentData.parentTournament.id}/>
        </IonRow>)
    }
}

function getGroupStage(tournamentData: any, groupStages: any[]) {
    if (groupStages && groupStages.length > 0) {
        return <React.Fragment>
            {
                tournamentData && getGroupStages(tournamentData).map((stage: any) => {
                    return (
                        <IonCol key={stage.id + "groupStage"} className={"group-match-col"}><GroupStage id={stage.id}/></IonCol>)
                })
            }
        </React.Fragment>;
    }

    return "";
}

export function getGrandFinalStageInElimination(stage: any) {
    return stage.childrenStages[stage.childrenStages.length - 1];
}


export function getGrandFinalStageInQualifier(stage: any) {
    return stage.childrenStages[stage.childrenStages.length - 2];
}

function getQualifierEliminationStages(tournamentData: any) {
    let stages = [];
    for (let x = 0; x < tournamentData.parentStages.length; x++) {
        let stageData = tournamentData.parentStages[x];
        if (stageData.tournamentStageType === "QUALIFIER") {
            stages.push(stageData);
            console.log(stageData)
        }
    }

    return stages;
}

function getDoubleEliminationStages(tournamentData: any) {
    let stages = [];
    for (let x = 0; x < tournamentData.parentStages.length; x++) {
        let stageData = tournamentData.parentStages[x];
        if (stageData.tournamentStageType === "DOUBLE_ELIMINATION") {
            stages.push(stageData);
        }
    }

    return stages;
}

function getSingleEliminationStages(tournamentData: any) {
    let stages = [];
    for (let x = 0; x < tournamentData.parentStages.length; x++) {
        let stageData = tournamentData.parentStages[x];
        if (stageData.tournamentStageType === "ELIMINATION") {
            stages.push(stageData);
        }
    }

    return stages;
}

function isMultiBracketTournament(tournamentData: any) {
    let stages = getPlayoffStages(tournamentData);

    for (let x = 0; x < stages.length; x++) {
        let stageData = tournamentData.stages[x];
        if (stageData.childrenStages && stageData.childrenStages.length > 0) {
            return true;
        }
    }

    return false;
}

function getTournamentPlayoffStages(tournamentData: any) {
    let playoffStages = getPlayoffStages(tournamentData);
    return getFormattedTournamentPlayoffStages(playoffStages);
}

function getFormattedTournamentPlayoffStages(playoffStages: any) {
    let children = [];
    for (let x = 0; x < playoffStages.length; x++) {

        let stage = playoffStages[x];
        let bracketIndex = x + 1;
        let maxBracketIndex = 5;
        let bracketClass = "bracket-" + bracketIndex;

        //If we have no more stages just end it with the last bracket stage
        if (x === playoffStages.length - 1) {
            bracketIndex = maxBracketIndex;
            bracketClass = "bracket-end";
        }


        children.push(<ul key={stage.id + "bracket"} className={"bracket " + bracketClass}><PlayoffStage
            id={stage.id}/>
        </ul>);
    }

    return children;
}

function getGroupStages(tournamentData: any) {
    let groupStages = [];

    for (let x = 0; x < tournamentData.stages.length; x++) {
        let stageData = tournamentData.stages[x];
        if (isGroupStage(stageData)) {
            groupStages.push(stageData);

        }
    }

    return groupStages;
}

function getPlayoffStages(tournamentData: any) {
    let playoffStages = [];

    for (let x = 0; x < tournamentData.stages.length; x++) {
        let stageData = tournamentData.stages[x];
        //Check if stage is playoff and if stage has no parent stage
        if (isPlayoffStage(stageData) && stageData.parentStage == null) {
            playoffStages.push(stageData);
        }
    }

    return playoffStages;
}


function isPlayoffStage(stageData: any) {
    return !isGroupStage(stageData);
}

function isGroupStage(stageData: any) {
    return stageData.tournamentStageCategory === "WEEK" || stageData.tournamentStageCategory === "GROUP" || stageData.tournamentStageCategory === "GROUP_STAGE";
}