import React from 'react';
import {getAPIURL, RestQueue} from "./RestQueue";

const ESPORT_TEAM_QUEUE = new RestQueue("teams/{id}");
const ESPORT_TEAM_LOGO_QUEUE = new RestQueue("teams/{id}/logo");

const ESPORT_MATCH_QUEUE = new RestQueue("match/{id}");
const ESPORT_MATCH_TREE_QUEUE = new RestQueue("match/{id}/tree");


const ESPORT_TOURNAMENT_QUEUE = new RestQueue("tournament/{id}");

const ESPORT_TOURNAMENT_STAGE_QUEUE = new RestQueue("tournament/stage/{id}");

const ESPORT_TOURNAMENT_STAGE_TREE_QUEUE = new RestQueue("tournament/stage/{id}/tree");

const ESPORT_TOURNAMENT_STAGE_QUALIFIED_TREE_QUEUE = new RestQueue("tournament/stage/{id}/qualifiedTree");

const ESPORT_TOURNAMENT_STAGE_MATCH_QUEUE = new RestQueue("tournament/stage/{id}/matches");

const ESPORT_TOURNAMENTS_ALL_QUEUE = new RestQueue("tournaments/all");

export function toDataURL(url: string, callback: (data: any) => void) {
    let xhr = new XMLHttpRequest();
    xhr.onload = function () {
        let reader = new FileReader();
        reader.onloadend = function () {
            callback(reader.result);
        }
        reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
}

export function getTeamLogo(teamId: number, callback: (data: any) => void) {
    ESPORT_TEAM_LOGO_QUEUE.fetchData(teamId, (data => {
        callback(data);
    }));
}

export function getTeamLogoPictureUrl(teamId: number) {
    return getAPIURL() + "teams/" + teamId + "/logo/picture";
}

export function fetchAllTournaments(callback: (data: any) => void) {
    ESPORT_TOURNAMENTS_ALL_QUEUE.fetchData(1, (data => {
        callback(data);
    }));
}

export function fetchTeam(teamId: number, callback: (data: any) => void) {
    ESPORT_TEAM_QUEUE.fetchData(teamId, (data => {
        getTeamLogo(teamId, (logoData => {
            data.baseLogo = logoData.logo;
            callback(data);
        }));
    }));
}

export function fetchMatch(teamId: number, callback: (data: any) => void) {
    ESPORT_MATCH_QUEUE.fetchData(teamId, (data => {
        callback(data);
    }));
}

export function fetchTournament(tournamentId: number, callback: (data: any) => void) {
    ESPORT_TOURNAMENT_QUEUE.fetchData(tournamentId, (data => {
        callback(data);
    }));
}

export function fetchTournamentStage(stageId: number, callback: (data: any) => void) {
    ESPORT_TOURNAMENT_STAGE_QUEUE.fetchData(stageId, (data => {
        callback(data);
    }));
}

export function fetchTournamentStageTree(stageId: number, callback: (data: any) => void) {
    ESPORT_TOURNAMENT_STAGE_TREE_QUEUE.fetchData(stageId, (data => {
        callback(data);
    }));
}

/**
 * Fetches a list of tournament trees for qualifier brackets (eg. one tree per qualifying match)
 * @param stageId
 * @param callback
 */
export function fetchTournamentStageQualifiedTree(stageId: number, callback: (data: any) => void) {
    ESPORT_TOURNAMENT_STAGE_QUALIFIED_TREE_QUEUE.fetchData(stageId, (data => {
        callback(data);
    }));
}

export function fetchTournamentMatchTree(matchId: number, callback: (data: any) => void) {
    ESPORT_MATCH_TREE_QUEUE.fetchData(matchId, (data => {
        callback(data);
    }));
}

export function fetchTournamentStageMatches(stageId: number, callback: (data: any) => void) {
    //TODO: Add the fetched matches to the match cache internally
    ESPORT_TOURNAMENT_STAGE_MATCH_QUEUE.fetchData(stageId, (data => {
        callback(data);
    }));
}


export function getDetailData(data: any, key: string) {
    for (let x = 0; x < data.detailData.length; x++) {
        let detailData = data.detailData[x];


        if (detailData.detailType.toUpperCase() === key.toUpperCase()) {
            return detailData.data;
        }
    }
}

