import React, {useState} from 'react';
import {IonContent, IonItem, IonList, IonPage, IonLabel} from '@ionic/react';
import Header from "../../components/Header";
import './PrivacyPolicy.css'


export const LegalDetailsPage: React.FC = () => {

    return (
        <IonPage>
            {/*<Header/>*/}
            <IonContent>
                <h1>Legal Disclosure</h1>
                <p>Information in accordance with Section 5 TMG
                <br/><br/>Taubhausstraße 37<br/>56112 Lahnstein<br/>Germany<br/>
                <h2>Contact Information</h2>
                Telephone: 015101075616<br/>E-Mail: <a href="mailto:info@evod.tv">info@evod.tv</a><br/>Internet
                address: <a href="https://evod.tv" target="_blank">evod.tv</a><br/><br/></p>
                <h2>Disclaimer</h2>
                <p>Accountability for content<br/>
                The contents of our pages have been created with the utmost care. However, we cannot guarantee the
                contents'
                accuracy, completeness or topicality. According to statutory provisions, we are furthermore responsible
                for
                our own content on these web pages. In this matter, please note that we are not obliged to monitor
                the transmitted or saved information of third parties, or investigate circumstances pointing to illegal
                activity.
                Our obligations to remove or block the use of information under generally applicable laws remain
                unaffected by this as per
                    §§ 8 to 10 of the Telemedia Act (TMG).

                <br/><br/>Accountability for links<br/>
                Responsibility for the content of
                external links (to web pages of third parties) lies solely with the operators of the linked pages. No
                violations were
                evident to us at the time of linking. Should any legal infringement become known to us, we will remove
                the respective
                link immediately.<br/><br/>Copyright<br/> Our web pages and their contents are subject to German
                copyright law. Unless
                expressly permitted by law, every form of utilizing, reproducing or processing
                works subject to copyright protection on our web pages requires the prior consent of the respective
                owner of the rights.
                Individual reproductions of a work are only allowed for private use.
                    The materials from these pages are copyrighted and any unauthorized use may violate copyright laws.</p>

                <br/><br/>
                <p>Quelle: </p><a href="http://www.translate-24h.de" target="_blank">translate-24h Englisch Übersetzungen</a> <br/><br/>
                <br/>
                <br/>
                <br/>
                <br/>
            </IonContent>
        </IonPage>
    );
};
