import React, {useState} from 'react';
import './Round.css';
import './Score.css';
import './BracketMatch.css'

import {IonButton, IonIcon, IonLabel} from "@ionic/react";
import {ellipsisVerticalOutline} from "ionicons/icons";

import {fetchMatch, fetchTeam} from "../../api/RestService";
import {TeamLogo} from "../organization/TeamLogoLinked";
import {getFirstRoundOfMatchWithVod, getWinningTeamFromMatch, getWinsFromMatch, IdProps} from "./Match";
import {Link} from "react-router-dom";
import {VISIBILITY_HANDLER} from "../../user/VisibilityHandler";
import {useStateValue} from "../StateProvider";
import {PlayVodButton} from "../button/PlayVodButton";
import {GoToMatchButton} from "../button/GoToUrlButton";
import {formatUnixTimeStamp} from "../../utils/DateUtils";


const BracketMatch: React.FC<IdProps> = ({id}) => {
    // @ts-ignore
    const [matchData, setMatchData] = useState<any>();

    const [showScore, setShowScore] = useState<any>(false);

    // @ts-ignore
    const [context, dispatch] = useStateValue();

    if (matchData == null || matchData.id != id) {
        fetchMatch(id, data => {
                setMatchData(data);
            }
        );
    }

    VISIBILITY_HANDLER.MATCH.addRenderMapCall(id, setShowScore);

    let winningTeamId = matchData ? getWinningTeamFromMatch(matchData) : -1;

    return (
        <React.Fragment>
            {matchData && getMatchTimeStampSpan(matchData, "match-date")}
            {matchData &&
            <div className="matchup">
                <div className="participants">
                    <div className="participant">
                        {
                            getScoreEntry(winningTeamId, matchData, matchData.teams[0])
                        }
                    </div>

                    {
                        getBracketInfoButton(matchData, matchData?.id)
                    }

                    <div className="participant">
                        {
                            getScoreEntry(winningTeamId, matchData, matchData.teams[1])
                        }
                    </div>
                </div>
            </div>
            }
        </React.Fragment>
    );
};

/**
 * Component used to render qualified "bracket matches" (only one team) for qualifiying playoffs
 * @param id Id of the winning/shown team
 * @param matchId Id of the previous match that determines the winning team
 * @constructor
 */
export const BracketMatchQualified: React.FC<BracketQualifiedProps> = ({id, matchId}) => {
    // @ts-ignore
    const [teamData, setTeamData] = useState<any>();

    //Just used to update the render of this component (state changed)
    const [stateChanged, setStateChanged] = useState<any>(false);


    if (id !== -1) {
        if (teamData == null || teamData.id != id) {
            fetchTeam(id, data => {
                    setTeamData(data);
                }
            );
        }

        //Hook onto the call of the previous match to update this component as well
        VISIBILITY_HANDLER.MATCH.addRenderMapCall(matchId, setStateChanged);
    }

    return (
        <React.Fragment>
            {teamData &&
            <div className="matchup">
                <div className="participants">
                    <div className="participant">
                        {getScoreTeamNameEntry({id: matchId}, id, teamData)}
                    </div>
                </div>
            </div>
            }
        </React.Fragment>
    );
};

interface BracketQualifiedProps {
    id: number;
    matchId: number;
}


export function getMatchTimeStampSpan(matchData: any, className: string) {
    let spanId = matchData.id + "timestamp";
    let date = formatUnixTimeStamp(matchData.date);
    let now = new Date().getTime();
    if (date.getTime() > now) {
        let timer = setInterval(function () {
            let element = document.getElementById(spanId);

            // Get today's date and time
            now = new Date().getTime();

            // Find the distance between now and the count down date
            let distance = date.getTime() - now;

            // Time calculations for days, hours, minutes and seconds
            let days = Math.floor(distance / (1000 * 60 * 60 * 24));
            let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            let seconds = Math.floor((distance % (1000 * 60)) / 1000);

            // Display the result in the element with id="demo"

            if (element) {
                // If the count down is finished, write some text
                if (distance < 0) {
                    element.innerHTML = formatUnixTimeStamp(matchData.date).toLocaleString();
                    clearInterval(timer);
                } else {
                    element.innerHTML = (days > 0 ? (days + "d ") : "") + hours + "h " + minutes + "m " + seconds + "s ";
                }
            }
        });
    }

    let live = undefined;

    if (matchData.endDate === -1 && date.getTime() <= now) {
        live = <span className={'live-tag'}>(Live)</span>;
    }

    return (
        <span id={spanId} className={className}>{formatUnixTimeStamp(matchData.date).toLocaleString()} {live}</span>)
}

const shownBracketInfoButtons: any[] = [];

export function toggleBracketInfo(id: any) {
    let hiddenDropdown = document.getElementById(id + "Detail");

    if (!hiddenDropdown) {
        return;
    }

    if (hiddenDropdown.classList.contains("hidden")) {
        hiddenDropdown.classList.remove("hidden");

        shownBracketInfoButtons.push(id);
    } else {
        hiddenDropdown.classList.add("hidden");

        if (shownBracketInfoButtons.includes(id)) {
            shownBracketInfoButtons.splice(shownBracketInfoButtons.indexOf(id), 1);
        }
    }
}

function getBracketInfoButton(matchData: any, matchIdentifier: any) {
    return (<div className={"middle"}>
        <div className={"seperator"}/>

        <div className="dropdown-div-match-detail">
            {!isTBD(matchData) && <button className={"detail-button"} onClick={() => {
                toggleBracketInfo(matchIdentifier);
            }}>
                <IonIcon className={"bracket-info-icon"} slot="icon-only" icon={ellipsisVerticalOutline}/>
            </button>}

            <div id={matchIdentifier + "Detail"} className={"match-detail-dropdown hidden"}>
                {matchData.id && !VISIBILITY_HANDLER.MATCH.isShown(matchData?.id) && !isTBD(matchData) &&
                <IonButton color="dark" onClick={() => {
                    //Just used to rerender
                    if (matchData) {
                        VISIBILITY_HANDLER.MATCH.setShown(matchData.id);
                        VISIBILITY_HANDLER.MATCH.updateRender(matchData.id);
                        toggleBracketInfo(matchData.id);
                    }
                }}>Show Match</IonButton>}

                {matchData.id && <IonLabel>
                    {getFirstRoundOfMatchWithVod(matchData) &&
                    <PlayVodButton vods={getFirstRoundOfMatchWithVod(matchData)?.vods}/>}
                    <GoToMatchButton id={matchData.id}/>
                </IonLabel>}

                {//Either show button if it is not shown (that means no fake match but existing match + id) or show it if we aren't showing the wildcard/fake match)
                    !VISIBILITY_HANDLER.MATCH.isAllVisible(matchData?.id) && matchData.id && !isNotDeterminedYet(matchData) && !hasTBDTeam(matchData) &&
                    <IonButton color="dark" onClick={() => {
                        //TODO: Reveal button does nothing for hidden wildcard matches yet
                        //Just used to rerender if match data actually exists
                        if (matchData.id) {
                            VISIBILITY_HANDLER.MATCH.setAllVisible(matchData.id);
                            VISIBILITY_HANDLER.MATCH.updateRender(matchData.id);
                            toggleBracketInfo(matchData.id);
                        }
                    }}>Reveal Score</IonButton>}
            </div>
        </div>
    </div>);
}

// Close the dropdown menu if the user clicks outside of it
window.onclick = function (event: any) {
    if (!event.target.matches('.detail-button') && !event.target.matches('.match-detail-dropdown') && !event.target.matches('.bracket-info-icon')) {
        for (let x = 0; x < shownBracketInfoButtons.length; x++) {
            toggleBracketInfo(shownBracketInfoButtons[x]);
        }
    }
};

function getScoreTeamNameEntry(matchData: any, winningTeamId: number, team: any, visible = false) {
    let hasTeam = team && team.name;
    return (
        <div
            className={(VISIBILITY_HANDLER.MATCH.isShown(matchData.id) || visible || !hasTeam ? "" : "hidden-keep-size ") + "name-entry"}>
            { //Undefined team = TBD
                hasTeam ?
                    (<Link className="float-right no-underline-link underline-hover" to={"/team/" + team.id}>
                        <TeamLogo height={25} teamId={team.id}/>
                        {getScoreTeamNameLabel(winningTeamId === team.id, matchData.id, team)}
                    </Link>) : getScoreTeamNameLabel(false, matchData.id, {name: "TBD"}, true)}
        </div>);
}

function getScoreTeamNameLabel(winner: boolean, matchId: number, team: any, visible = false) {
    let name = team.name;
    let maxLength = 20;
    if (name.length > maxLength) {
        name = name.substr(0, maxLength) + "..";
    }

    return <span
        className={(!visible ? (VISIBILITY_HANDLER.MATCH.isAllVisible(matchId) ? (winner ? "winner-name" : "loser-name") : "") : "") + " color-contrast name-label"}>{name}</span>
}

function getScoreNumberEntry(winningTeamId: number, matchData: any, team: any) {
    //Undefined team = TBD
    if (!team) {
        return "";
    }

    return (
        <div className={"score-entry"}>
            <span
                className={(VISIBILITY_HANDLER.MATCH.isAllVisible(matchData.id) ? "" : "hidden-keep-size ") + (winningTeamId === team.id ? "winner" : "loser") + " score-entry"}
                id={matchData.id + "" + team.id + "scoreLabel"}>{VISIBILITY_HANDLER.MATCH.isAllVisible(matchData.id) ? getWinsFromMatch(team.id, matchData) : 0}</span>
        </div>)
}

export function hasTBDTeam(matchData: any) {
    return matchData.teams.length < 2;
}


export function isTBD(matchData: any) {
    return matchData.teams.length < 1;
}


export function isInFuture(matchData: any) {
    let date = formatUnixTimeStamp(matchData.date);
    let now = new Date().getTime();
    return date.getTime() > now;
}


export function isNotDeterminedYet(matchData: any) {
    for (let x = 0; x < matchData.teams.length; x++) {
        if (getWinsFromMatch(matchData.teams[x].id, matchData) < 0) {
            return true;
        }
    }

    return false;
}


function getScoreEntry(winningTeamId: number, matchData: any, team: any) {
    if (matchData.endDate === -1 && team !== undefined) {
        winningTeamId = team.id;
    }

    return (
        <React.Fragment>
            {
                getScoreTeamNameEntry(matchData, winningTeamId, team)
            }

            {
                getScoreNumberEntry(winningTeamId, matchData, team)
            }
        </React.Fragment>)
}

/*function getPlaceholderEntry(matchIdentifier: any, team: any, previousMatches: [], nextMatch: any) {
    return (
        <React.Fragment>
            {
                getScoreTeamNameEntry(matchIdentifier, {
                    id: -1,
                    previousMatches: previousMatches,
                    nextMatch: nextMatch
                }, team.id, team)
            }
        </React.Fragment>)
}


function shouldShowWildcardMatch(matchIdentifier: any, previousMatches: any, nextMatch: any) {
    if (!previousMatches || VISIBILITY_HANDLER.MATCH.isShown(nextMatch?.id)) {
        return true;
    }

    for (let x = 0; x < previousMatches.length; x++) {
        if (!VISIBILITY_HANDLER.MATCH.isAllVisible(previousMatches[x].id)) {
            return false;
        }
    }

    return VISIBILITY_HANDLER.MATCH.isShown(nextMatch?.id);
}*/

export default BracketMatch;
