import React from 'react';


interface ImageProps {
    country: string,
    width: number;
}

export const RegionFlag: React.FC<ImageProps> = ({country, width}) => {
    return (
        <img width={width + "px"} src={"/assets/flags/" + getCountryCode(country) + ".svg"}/>
    );
};

export function getCountryCode(string: string) {
    if(string == null) {
        return "";
    }

    if(string.toLowerCase().includes("europe")) {
        return "eu";
    } else if(string.toLowerCase().includes("america")) {
        return "us";
    } else {
        return "";
    }
}