import React, {useState} from 'react';
import './Round.css';
import './Score.css';
import './Match.css';

import {IonButton, IonItem, IonLabel, IonGrid, IonRow, IonCol} from "@ionic/react";

import {fetchMatch} from "../../api/RestService";
import TeamLogoLinked, {TeamLogo} from "../organization/TeamLogoLinked";
import {formatUnixTimeStamp} from "../../utils/DateUtils";
import {VISIBILITY_HANDLER} from "../../user/VisibilityHandler";
import {useStateValue} from "../StateProvider";
import {PlayVodButton} from "../button/PlayVodButton";
import {GoToMatchButton} from "../button/GoToUrlButton";
import {Link} from "react-router-dom";
import {getVsScore} from "../../pages/MatchPage";
import {getMatchTimeStampSpan, isInFuture, isNotDeterminedYet, isTBD} from "./BracketMatch";


export interface IdProps {
    id: number;
}

const Match: React.FC<IdProps> = ({id}) => {
    // @ts-ignore
    const [matchData, setMatchData] = useState<any>();

    const [showScore, setShowScore] = useState<boolean>(false);

    // @ts-ignore
    const [context, dispatch] = useStateValue();

    if (matchData == null || matchData.id != id) {
        fetchMatch(id, data => {
                setMatchData(data);
            }
        );
    }

    let winningTeamId = matchData ? getWinningTeamFromMatch(matchData) : -1;

    return (
        <React.Fragment>{matchData &&
        <IonItem color="darkblue-secondary ion-text-wrap" id={"round" + id} className="revealedScore transitionScore">
            <IonGrid>
                <IonRow>
                    <IonCol className={"ion-text-center"}>
                        <IonLabel className={"date-label ion-text-center"}>{formatUnixTimeStamp(matchData.date).toLocaleString()}</IonLabel>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <div className={"matchDiv"}>{
                    //Show team scores
                    matchData.teams.map((team: any) => {
                        return (
                            <div className={"matchDiv2"} key={team.id + "scoreEntry" + id}>

                                        <TeamLogoLinked height={50} teamId={team.id}/>

                                        {//Why css classes instead of show property? If we hide it using show property it will stick to other entries as well and it won't reserve the space and will shift when revelead
                                            <span className={(VISIBILITY_HANDLER.MATCH.isAllVisible(matchData.id) ? "" : "hidden-keep-size ") + (winningTeamId === team.id ? "winner" : "loser") + " score-label"} id={matchData.id + "" + team.id + "scoreLabel"}>{getWinsFromMatch(team.id, matchData)}</span>}

                            </div>)
                    })

                    }</div>
                    <IonCol className={"no-padding match-buttons"}>
                        {
                            !isNotDeterminedYet(matchData) && <IonButton id={"reveal" + id}
                                       className={VISIBILITY_HANDLER.MATCH.isAllVisible(matchData.id) ? "hidden-keep-size" : ""}
                                       color="dark"
                                       onClick={() => {
                                           //Set to show score for this instance
                                           setShowScore(true);

                                           //Just used to rerender
                                           VISIBILITY_HANDLER.MATCH.setAllVisible(matchData.id);

                                           //Update to rerender
                                           matchData.teams.map((team: any) => {
                                               let roundElement = document.getElementById(matchData.id + "" + team.id + "scoreLabel");
                                               roundElement?.classList.remove("hidden-keep-size");
                                               roundElement?.classList.add("revealedScore");
                                           });
                                       }}>Reveal Score</IonButton>
                        }
                        <PlayVodButton vods={getFirstRoundOfMatchWithVod(matchData)?.vods}/>
                        <GoToMatchButton id={matchData.id}/>
                    </IonCol>
                </IonRow>



            </IonGrid>


        </IonItem>}</React.Fragment>
    );
};

export const TwoMatch: React.FC<IdProps> = ({id}) => {
    // @ts-ignore
    const [matchData, setMatchData] = useState<any>();

    const [showScore, setShowScore] = useState<boolean>(false);

    // @ts-ignore
    const [context, dispatch] = useStateValue();

    if (matchData == null || matchData.id != id) {
        fetchMatch(id, data => {
                setMatchData(data);
            }
        );
    }

    let winningTeamId = matchData ? getWinningTeamFromMatch(matchData) : -1;

    return (
        <React.Fragment>{matchData &&
        <IonItem color="darkblue-secondary ion-text-wrap" id={"round" + id} className="revealedScore transitionScore">
            <IonGrid>
                <IonRow>
                    <IonCol>
                        <div className="match-item-vs-div">
                            <div className="match-item-header-team"><Link className="float-right no-underline-link underline-hover" to={"/team/" + (matchData.teams.length >= 1? matchData.teams[0].id : -1)}>
                                {/*React fragment is needed so it will update on page change*/}
                                <React.Fragment><TeamLogo teamId={matchData.teams.length >= 1? matchData.teams[0].id : -1} height={35}/></React.Fragment>
                                <div className="match-team-name">{matchData.teams.length >= 1? matchData.teams[0].name : "TBD"}</div>
                            </Link></div>
                            {
                                getMatchFormatted(matchData, !showScore && !VISIBILITY_HANDLER.MATCH.isAllVisible(matchData.id))
                            }
                            <div className="match-item-header-team"><Link className="float-left no-underline-link underline-hover" to={"/team/" + (matchData.teams.length >= 2? matchData.teams[1].id : -1)}>
                                {/*React fragment is needed so it will update on page change*/}
                                <React.Fragment><TeamLogo teamId={matchData.teams.length >= 2? matchData.teams[1].id : -1} height={35}/></React.Fragment>
                                <div className="match-team-name">{matchData.teams.length >= 2? matchData.teams[1].name : "TBD"}</div>
                            </Link></div>
                        </div>
                    </IonCol>
                    <IonCol size={"3"} className={"match-buttons"}>
                        <IonGrid>
                            <IonRow>
                                <IonCol>
                                    {
                                        !isInFuture(matchData) && <IonButton id={"reveal" + id}
                                                   className={VISIBILITY_HANDLER.MATCH.isAllVisible(matchData.id) ? "hidden-keep-size" : ""}
                                                   color="dark"
                                                   onClick={() => {
                                                       //Set to show score for this instance
                                                       setShowScore(true);

                                                       //Just used to rerender
                                                       VISIBILITY_HANDLER.MATCH.setAllVisible(matchData.id);

                                                       //Update to rerender
                                                       matchData.teams.map((team: any) => {
                                                           let roundElement = document.getElementById(matchData.id + "" + team.id + "scoreLabel");
                                                           roundElement?.classList.remove("hidden-keep-size");
                                                           roundElement?.classList.add("revealedScore");
                                                       });
                                                   }}>Reveal Score</IonButton>
                                    }
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    {!isInFuture(matchData) && <PlayVodButton vods={getFirstRoundOfMatchWithVod(matchData)?.vods}/>}
                                    <GoToMatchButton id={matchData.id}/>
                                </IonCol>
                            </IonRow>
                        </IonGrid>


                    </IonCol>
                </IonRow>


            </IonGrid>

        </IonItem>}</React.Fragment>
    );
};

function getMatchFormatted(data: any, hideScore: boolean) {
    let scoreOne = 0;
    let scoreTwo = 0;
    let totalScoresTeamOne = 0;
    let totalScoresTeamTwo = 0;

    if(data.teams.length >= 1) {
        scoreOne = getWinsFromMatch(data.teams[0].id, data);
        totalScoresTeamOne = getTotalScoresFromMatch(data.teams[0].id, data)
    }

    if(data.teams.length >= 2) {
        scoreTwo = getWinsFromMatch(data.teams[1].id, data);
        totalScoresTeamTwo = getTotalScoresFromMatch(data.teams[1].id, data)
    }



    return (<div className="match-header-vs">
        <div className="match-header-vs-note"><span>{data.tournamentStage.name}</span></div>
        <div className={"match-header-vs-score" + (hideScore ? " hidden-keep-size" : "")}><span
            className={scoreOne > scoreTwo ? "winner" : "loser"}>{scoreOne}</span><span
            className="match-item-divider"> - </span><span className={scoreTwo > scoreOne ? "winner" : "loser"}>{scoreTwo}</span>
        </div>
        <div className={"match-header-vs-note-date" + (hideScore ? " hidden-keep-size" : "")}>
            ({totalScoresTeamOne} - {totalScoresTeamTwo})
        </div>
        <div className="match-header-vs-note">{getMatchTimeStampSpan(data, "")}</div>
    </div>)
}

export function getFirstRoundOfMatch(matchData: any) {
    return matchData.rounds[0];
}

export function getFirstRoundOfMatchWithVod(matchData: any) {
    //No rounds tracked for this match == no vods
    if(!matchData.rounds || matchData.rounds.length === 0) {
        return {vods: []};
    }

    let index = 0;
    let roundData =  matchData.rounds[index];

    while((!roundData || !roundData.vods || roundData.vods.length === 0) && index < matchData.rounds.length) {
        roundData =  matchData.rounds[++index];
    }

    return roundData;
}


export function getWinningTeamFromMatch(matchData: any) {
    let highestScore = 0;
    let highestScoreTeamId = -1;

    if(matchData.endDate === -1) {
        return highestScoreTeamId;
    }

    outerLoop: for (let x = 0; x < matchData.teams.length; x++) {
        let team = matchData.teams[x];
        let teamScore = getWinsFromMatch(team.id, matchData);

        if (teamScore > highestScore) {
            highestScore = teamScore;
            highestScoreTeamId = team.id;
        }
    }

    return highestScoreTeamId;
}

export function getWinsFromMatch(teamId: number, matchData: any) {
    if (matchData.matchData) {
        for (let x = 0; x < matchData.matchData.length; x++) {
            let matchDataEntry = matchData.matchData[x];

            if (matchDataEntry.team.id == teamId) {
                return matchDataEntry.score;
            }
        }
    }

    let wins = 0;
    outerLoop: for (let x = 0; x < matchData.rounds.length; x++) {
        let round = matchData.rounds[x];
        let scoreMap = new Map<number, number>();
        for (let y = 0; y < round.scores.length; y++) {
            let score = round.scores[y];
            scoreMap.set(score.team.id, score.score);
        }

        let teamScore = scoreMap.get(teamId);

        for (let [key, value] of scoreMap) {

            if (value > teamScore! && teamScore !== key) {
                continue outerLoop;
            }
        }

        wins++;
    }

    return wins;
}

export function getTotalScoresFromMatch(teamId: number, matchData: any) {
    let goals = 0;
    outerLoop: for (let x = 0; x < matchData.rounds.length; x++) {
        let round = matchData.rounds[x];
        let scoreMap = new Map<number, number>();
        for (let y = 0; y < round.scores.length; y++) {
            let score = round.scores[y];

            if (score.team.id == teamId) {
                goals += score.score;
            }
        }

    }

    return goals;
}

export default Match;
