import React, {useState} from 'react';
import './Round.css';
import {IonButton, IonItem, IonLabel} from "@ionic/react";
import {useStateValue} from "../StateProvider";
import './Score.css';
import TeamLogoLinked from "../organization/TeamLogoLinked";
import {VISIBILITY_HANDLER} from "../../user/VisibilityHandler";
import {PlayVodButton} from "../button/PlayVodButton";

interface RoundProps {
    data: any;
    matchId: number,
    setShowMatchScore: any;
}

const Round: React.FC<RoundProps> = ({data, matchId, setShowMatchScore}) => {
    // @ts-ignore
    const [context, dispatch] = useStateValue();

    const [showScore, setShowScore] = useState<boolean>(false);

    /*if (!fetched) {
        fetched = true;

        let newTeamData = new Map<number, any>();
        data.scores.map((scoreEntry: any) => {
            fetchTeam(scoreEntry.team.id, (returnData) => {
                newTeamData.set(scoreEntry.team.id, returnData);

                console.log("Fetched team data size" + newTeamData.size);

                //Fetched all entries, update data
                if (newTeamData.size === data.scores.length) {
                    setTeamData(newTeamData);
                }
            });
        })
    }*/

    let winningTeamId = getWinningTeamFromRound(data);

    return (
        <IonItem color="darkblue-secondary" id={"round" + data.id} className={(data.roundOffset === 0 ? "revealedScore" : VISIBILITY_HANDLER.ROUND.isShown(data.id) || VISIBILITY_HANDLER.MATCH.isAllVisible(matchId)? "revealedScore" : "hidden-keep-size") + " transitionScore"}>
            <IonLabel>
                {data.map.name}
            </IonLabel>
            {
                //Show team scores
                data.scores.map((scoreEntry: any) => {
                    return (
                        <React.Fragment key={scoreEntry.id + "scoreEntry"}>
                            <TeamLogoLinked height={50} teamId={scoreEntry.team.id}/>
                            <br/>
                            <IonLabel
                                className={(showScore || VISIBILITY_HANDLER.ROUND.isAllVisible(data.id) || VISIBILITY_HANDLER.MATCH.isAllVisible(matchId)? "" : "hidden-keep-size ") + (winningTeamId === scoreEntry.team.id ? "winner" : "loser") + " score-label"}>{scoreEntry.score}</IonLabel>
                        </React.Fragment>)
                })

            }

            <IonLabel>{data.vods.length > 0 && <PlayVodButton vods={data.vods}/>}</IonLabel>
            {
                !showScore ? <IonButton color="dark" onClick={() => {
                    //Set to show score for this instance
                    setShowScore(true);
                    VISIBILITY_HANDLER.ROUND.setShown(data.id);

                    if (data.nextRound.id >= 0) {
                        //Reveal following entry if it exists

                        //We need to set this boolean because otherwise it would rerender and apply hidden again
                        VISIBILITY_HANDLER.ROUND.setShown(data.nextRound.id);

                        //Update to rerender
                        let roundElement = document.getElementById("round" + data.nextRound.id);
                        roundElement!.classList.remove("hidden-keep-size");
                        roundElement!.classList.add("revealedScore");
                    } else {
                        setShowMatchScore(true);
                    }
                }}>Reveal Score</IonButton> : ""
            }

        </IonItem>
    );
};

export function getWinningTeamFromRound(roundData: any) {
    let highestScore = 0;
    let teamHighest = -1;

    for (let x = 0; x < roundData.scores.length; x++) {
        let scoreEntry = roundData.scores[x];

        if (scoreEntry.score > highestScore) {
            teamHighest = scoreEntry.team.id;
            highestScore = scoreEntry.score;
        }
    }

    return teamHighest;
}


export default Round;
