import React, {useState} from 'react';
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonItem,
    IonList,
    IonPage,
    IonRow,
    IonLabel,
    IonIcon
} from '@ionic/react';
import './MatchPage.css';
import './TeamDetail.css';


import Header from "../components/Header";

import {fetchTeam, getDetailData} from "../api/RestService";
import TeamLogoLinked from "../components/organization/TeamLogoLinked";
import {IdPageProps} from "./MatchPage";
import Match, {TwoMatch} from "../components/match/Match";
import VodPlayer from "../components/VodPlayer";
import TeamPlayer from "../components/organization/TeamPlayer";
import {TournamentItem} from "../components/tournament/TournamentItem";
import {setDescription, setTabTitle, VOD_PLAYER} from "../App";
import {LiquipediaButton} from "../components/LiquipediaButton";
import {NotFoundPage} from "./NotFoundPage";
import {chevronDownCircleOutline, chevronUpCircleOutline} from "ionicons/icons";
import {RegionFlag} from "../components/RegionFlag";
import {normalizeEnumString} from "../utils/StringUtils";
import {getFormattedTournamentDate} from "./tournament/TournamentPage";
import {getGameByDatabaseName} from "../data/Game";
import {GameContainerProps} from "./LandingPage";


const TeamDetail: React.FC<IdPageProps> = ({match, history}) => {
    const [teamData, setTeamData] = useState<any>();


    if (teamData == null || String(teamData.id) !== match.params.id) {
        fetchTeam(Number(match.params.id), team => {
                setTeamData(team);
                setTabTitle(team.name);
                setDescription(getTeamDescription(team));
            }
        );
    }

    if (teamData && teamData.error) {
        return <NotFoundPage/>
    }


    return (
        <IonPage>
            {/*<Header/>*/}
            <IonContent>
                <IonGrid className={"no-padding"}>
                    <IonRow className={"no-padding"}>
                        <IonCol className={"no-padding"}>
                            <IonList>
                                <IonItem color="darkblue center"> < IonLabel className="ion-text-center">Active
                                    Players</IonLabel></IonItem>
                                <TeamPlayer playerId={-1}></TeamPlayer>
                                <TeamPlayer playerId={-1}></TeamPlayer>
                                <TeamPlayer playerId={-1}></TeamPlayer>
                                <IonItem color="darkblue center"><IonLabel className="ion-text-center">Recent
                                    Tournaments</IonLabel></IonItem>
                                {teamData && teamData.tournaments?.sort((a: any, b: any) => {
                                    // let aDate = getDetailData(a, "START_DATE");
                                    // let bDate = getDetailData(b, "START_DATE");
                                    //
                                    // if(aDate < bDate) {
                                    //     return -1;
                                    // }
                                    //
                                    // if(aDate >bDate) {
                                    //     return 1;
                                    // }

                                    return 0;
                                }).map((tournament: any) => {
                                    return (<TournamentItem key={tournament.id + "Tournament" + teamData.id}
                                                            data={tournament}/>);
                                })}
                                <MatchesItem id={teamData && teamData.id} title={"Recent Matches"} recentMatches={teamData && teamData.recentMatches}/>
                            </IonList>
                        </IonCol>
                        <IonCol className={"no-padding"}>
                            <IonItem color="darkblue center"><IonLabel
                                className="ion-text-center">{teamData && teamData.name}</IonLabel>
                                {teamData && <LiquipediaButton vods={teamData}/>}
                            </IonItem>
                            <IonItem color="darkblue-third">
                                <div className={"tournament-page-header"}>
                                    <div className={"tournament-page-header-container"}>
                                        <table>
                                            <tbody>
                                            <tr>
                                                <td className={"header-info-item"}>
                                                    <TeamLogoLinked teamId={Number(match.params.id)} height={50}/>
                                                </td>
                                                <td className={"header-info-item"}>
                                                    <p className={"tournament-page-info-headline"}>Game</p>
                                                    <h4>{teamData && getGameByDatabaseName(teamData.game)?.name}</h4>
                                                </td>
                                                <td className={"header-info-item"}>
                                                    <p className={"tournament-page-info-headline"}>Region</p>
                                                    <h4>Unknown</h4>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
            {/*VOD_PLAYER*/}
        </IonPage>
    );
};


export default TeamDetail;

interface MatchesListProps {
    recentMatches: [];
    title: string;
    id: string;
}


export const MatchesItem: React.FC<MatchesListProps> = ({recentMatches, title, id}) => {
    return (<React.Fragment>
            <IonItem color="darkblue center">
                <IonLabel className="ion-text-center">{title}</IonLabel>
                {recentMatches && recentMatches.length > 0 &&
                <IonButton onClick={() => {
                    toggleMatchDiv(id);
                }}>
                    <IonIcon slot="icon-only" icon={chevronDownCircleOutline}></IonIcon></IonButton>}
            </IonItem>
            {
                recentMatches && <div id={"RecentMatchDiv" + id} className={"hidden"}>
                    {recentMatches && recentMatches?.map((match: any) => {
                        return (<TwoMatch key={match.id + "Match"} id={match.id}/>);
                    })}
                </div>

            }
        </React.Fragment>
    )
}

function getTeamDescription(teamData: any) {
    let desc = teamData.name;
    desc = 'All about the team ' + desc;
    desc += ' playing in the esport game ' + getGameByDatabaseName(teamData.game)?.name + '. Watch and stream recent matches, vods and tournaments.';
    return desc;
}




function toggleMatchDiv(id: string) {
    let div = document.getElementById("RecentMatchDiv" + id);

    if (div) {
        if (div.classList.contains("hidden")) {
            div.classList.remove("hidden");
        } else {
            div.classList.add("hidden");
        }
    }
}
