import React, {useEffect, useState} from 'react';
import {IonContent, IonList, IonPage, IonButton} from '@ionic/react';
import './MatchPage.css';
import '../components/match/Score.css';

import {RouteComponentProps} from "react-router";
import Header from "../components/Header";

import {fetchMatch, fetchTournament} from "../api/RestService";
import Round from "../components/match/Round";
import VodPlayer from "../components/VodPlayer";
import TeamLogoLinked, {TeamLogo} from "../components/organization/TeamLogoLinked";
import {getTotalScoresFromMatch, getWinsFromMatch, IdProps} from "../components/match/Match";
import {Link} from "react-router-dom";
import {formatUnixTimeStamp} from "../utils/DateUtils";
import {VISIBILITY_HANDLER} from "../user/VisibilityHandler";
import {setDescription, setTabTitle, VOD_PLAYER} from "../App";
import {NotFoundPage} from "./NotFoundPage";
import {getMatchTimeStampSpan} from "../components/match/BracketMatch";
import {normalizeEnumString} from "../utils/StringUtils";
import {getGameByDatabaseName} from "../data/Game";


export interface IdPageProps extends RouteComponentProps<{
    id: string;
}> {
}


const MatchPage: React.FC<IdPageProps> = ({match}) => {
    const [matchData, setMatchData] = useState<any>();

    const [showScore, setShowScore] = useState<boolean>(false);

    let matchCount = 0;

    if (!matchData || String(matchData.id) !== match.params.id) {
        fetchMatch(Number(match.params.id), data => {
                setMatchData(data);
                setTabTitle(getMatchName(data));
                setDescription(getMatchDescription(data));
            }
        );
    }

    if(matchData && matchData.error) {
        return <NotFoundPage/>
    }

    return (
        <IonPage>
            {/*<Header/>*/}
            <IonContent>
                {matchData && <React.Fragment>
                    <div className="vs-div">
                        <div className="match-header-team"><Link className="float-right no-underline-link underline-hover" to={"/team/" + (matchData.teams.length >= 1? matchData.teams[0].id : -1)}>
                            {/*React fragment is needed so it will update on page change*/}
                            <React.Fragment><TeamLogo teamId={matchData.teams.length >= 1? matchData.teams[0].id : -1} height={50}/></React.Fragment>
                            <div className="match-team-name">{matchData.teams.length >= 1? matchData.teams[0].name : "TBD"}</div>
                        </Link></div>
                        {
                            getVsScore(matchData, !showScore && !VISIBILITY_HANDLER.MATCH.isAllVisible(matchData.id))
                        }
                        <div className="match-header-team"><Link className="float-left no-underline-link underline-hover" to={"/team/" + (matchData.teams.length >= 2? matchData.teams[1].id : -1)}>
                            {/*React fragment is needed so it will update on page change*/}
                            <React.Fragment><TeamLogo teamId={matchData.teams.length >= 2? matchData.teams[1].id : -1} height={50}/></React.Fragment>
                            <div className="match-team-name">{matchData.teams.length >= 2? matchData.teams[1].name : "TBD"}</div>
                        </Link></div>

                    </div>
                    <IonList>
                        {
                            matchData.rounds.map((roundEntry: any) => {
                                roundEntry.roundOffset = matchCount;
                                roundEntry.nextRound = -1;
                                matchCount++;

                                if (matchData.rounds.length > matchCount) {
                                    roundEntry.nextRound = matchData.rounds[matchCount];
                                }

                                return (<Round data={roundEntry} matchId={matchData.id} setShowMatchScore={(b: boolean) => {
                                    setShowScore(b);
                                    VISIBILITY_HANDLER.MATCH.setAllVisible(matchData.id);
                                }} key={roundEntry.id}></Round>);
                            })
                        }
                    </IonList>
                </React.Fragment>
                }
            </IonContent>

            {/*VOD_PLAYER*/}
        </IonPage>
    );
};



export function getVsScore(data: any, hideScore: boolean) {
    let scoreOne = 0;
    let scoreTwo = 0;
    let totalScoresTeamOne = 0;
    let totalScoresTeamTwo = 0;

    if(data.teams.length >= 1) {
        scoreOne = getWinsFromMatch(data.teams[0].id, data);
        totalScoresTeamOne = getTotalScoresFromMatch(data.teams[0].id, data)
    }

    if(data.teams.length >= 2) {
        scoreTwo = getWinsFromMatch(data.teams[1].id, data);
        totalScoresTeamTwo = getTotalScoresFromMatch(data.teams[1].id, data)
    }

    return (<div className="match-header-vs">
        <Link className="no-underline-link underline-hover" to={"/tournament/" + data.tournament.id}><div className="match-header-vs-note"><span>{data.tournament.name}</span></div></Link>
        <div className="match-header-vs-note"><span>{data.tournamentStage.name}</span></div>
        <div className={"match-header-vs-score" + (hideScore ? " hidden-keep-size" : "")}><span
            className={scoreOne > scoreTwo ? "winner" : "loser"}>{scoreOne}</span><span
            className="divider"> - </span><span className={scoreTwo > scoreOne ? "winner" : "loser"}>{scoreTwo}</span>
        </div>
        <div className={"match-header-vs-note-date" + (hideScore ? " hidden-keep-size" : "")}>
            ({totalScoresTeamOne} - {totalScoresTeamTwo})
        </div>
        <div className="match-header-vs-note"><div className="match-header-vs-note">{getMatchTimeStampSpan(data, "")}</div></div>
    </div>)
}

function getVsChildren(data: any) {
    const children = [];

    for (let x = 0; x < data.teams.length; x++) {
        let team = data.teams[x];
        if (x === 0) {
            //children.push(<>score.team.name <TeamLogo teamId={score.team.id}/> " vs "</>);
            //console.log(vsString);
            children.push(<div className="team-match-div" key={data.id + "team" + team.id}><span
                className="vs-span">{team.name}</span> <TeamLogoLinked
                height={7} teamId={team.id}/> <span className="vs-span winner font-bigger">0</span><span
                className="vs-span"> vs </span></div>);
        } else if (x === data.teams.length - 1) {
            //vsString += score.team.name;
            children.push(<div className="team-match-div" key={data.id + "team" + team.id}><span
                className="vs-span loser font-bigger">0</span><TeamLogoLinked
                height={7} teamId={team.id}/> <span className="vs-span">{team.name}</span></div>);
        } else {
            // vsString += score.team.name + " vs ";
            children.push(<div className="team-match-div" key={data.id + "team" + team.id}><TeamLogoLinked
                height={7} teamId={team.id}/> <span className="vs-span">{team.name} vs</span></div>);
        }
    }

    return children;
}

function getVsChildren2(data: any) {
    const children = [];

    for (let x = 0; x < data.teams.length; x++) {
        let team = data.teams[x];
        if (x === 0) {
            //children.push(<>score.team.name <TeamLogo teamId={score.team.id}/> " vs "</>);
            //console.log(vsString);
            children.push(<span className="vs-span">{team.name}</span>);
        } else if (x === data.teams.length - 1) {
            //vsString += score.team.name;
            children.push(<span className="vs-span">{team.name}</span>);
        } else {
            // vsString += score.team.name + " vs ";
            children.push(<span className="vs-span">{team.name}</span>);
        }
    }

    return children;
}

function getMatchDescription(matchData: any) {
    let desc = getMatchName(matchData);
    desc = 'Watch/stream the match vods ' + desc;
    desc += ' at ' + formatUnixTimeStamp(matchData.date).toUTCString() + ' in the ' + matchData.tournamentStage.name + ' of the ' + matchData.tournament.name + ' played in the game ' + getGameByDatabaseName(matchData.game)?.name;

    return desc;
}

function getMatchName(matchData: any) {
    let name = '';

    for (let x = 0; x < matchData.teams.length; x++) {
        let team = matchData.teams[x];
        name += team.name + ' vs ';
    }

    name = name.substr(0, name.length - ' vs '.length);

    return name;
}


export default MatchPage;
