import {isProductionEnv} from "../App";

export class RestQueue {
    url: string;
    callbackQueue: Map<number, [(data: any) => void]>;
    cache = new Map<number, any>();

    constructor(url: string) {
        this.url = url;
        this.callbackQueue = new Map();
    }

    fetchData(id: number, callback: (data: any) => void) {
        if(this.callbackQueue.has(id)) {
            this.addCallback(id, callback);
        } else {
            this.addCallback(id, callback);
            if(this.cache.has(id)) {
                this.callCallbacks(id, this.cache.get(id));
            } else {
                fetchFromAPI(this.url.replace('{id}', id + ""), (data => {
                    this.handleDataArrive(id, data);
                }));
            }
        }
    }

    async fetchDataAsync(id: number) {
        let response = await fetchFromAPIAsync(this.url.replace('{id}', id + ""));
        this.handleDataArrive(id, response);
        return response;
    }

    handleDataArrive(id: number, data: any) {
        this.cache.set(id, data);
        this.callCallbacks(id, data);
    }

    addCallback(id: number, callback: (data: any) => void) {
        if(this.callbackQueue.has(id)) {
            this.callbackQueue.get(id)!.push(callback);
        } else {
            this.callbackQueue.set(id, [callback]);
        }
    }

    callCallbacks(id: number, data: any) {
        if(this.callbackQueue.has(id)) {
            this.callbackQueue.get(id)!.map(callback => callback(data));
            this.callbackQueue.delete(id);
        }
    }
}

export function getAPIURL() {
    if (isProductionEnv()) {
        return "https://api.evod.tv/api/";
    } else {
        return "http://localhost/api/";
    }
}

export async function fetchFromAPIAsync(subUrl: string) {
    return await (await fetch(getAPIURL() + subUrl)).json();
}


export function post(subUrl: string, data: any, callback: (data: any) => void) {
    fetch(getAPIURL() + subUrl, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json'
        },
    }).then(res => res.json())
        .then((data) => {
            callback(data);
        }).catch(console.log);
}


export function fetchFromAPI(subUrl: string, callback: (data: any) => void) {
    fetch(getAPIURL() + subUrl)
        .then(res => res.json())
        .then((data) => {
            callback(data);
        })
        .catch(console.log);
}