import React from 'react';
import {IonButton, IonFooter, IonIcon, IonToolbar, IonLabel} from '@ionic/react';
import {Link} from "react-router-dom";

import './VodPlayer.css';

import {useStateValue} from './StateProvider';
import {chevronDownCircle, chevronUpCircle} from "ionicons/icons";


interface VodPlayerProps {
    url: any;
}

const VodPlayer: React.FC<VodPlayerProps> = ({url}) => {
    // @ts-ignore
    const [context, dispatch] = useStateValue();

    return (
        <React.Fragment key={"vod-player"}>

            <div
                className={context.vodPlayer.visible === 'no' ? "hidden" : context.vodPlayer.visible === 'big' ? "footerToolbar" : "miniPlayer"}>
                <div className="vodPlayer">
                    <iframe
                        className={context.vodPlayer.visible === 'no' ? "hidden" : context.vodPlayer.visible === 'big' ? "vodPlayer" : "miniPlayerInner"}
                        src={context.vodPlayer.url}
                        height="100%"
                        width="100%"
                        scrolling="no"
                        frameBorder="no"
                        allowFullScreen={true}>
                    </iframe>
                </div>
            </div>
            <IonFooter className="ion-no-border">
                <IonToolbar color="dark-medium">
                    <IonButton onClick={() => {
                        dispatch({
                            type: 'changeVodUrl',
                            newData: {
                                url: context.vodPlayer.url,
                                visible: context.vodPlayer.visible === 'big' ? "no" : 'big'
                            }
                        })
                    }}>
                        <IonIcon slot="icon-only"
                                 icon={context.vodPlayer.visible === 'big' ? chevronDownCircle : chevronUpCircle}/>
                    </IonButton>
                    <IonButton onClick={() => {
                        dispatch({
                            type: 'changeVodUrl',
                            newData: {
                                url: context.vodPlayer.url,
                                visible: context.vodPlayer.visible === 'mini' ? "no" : 'mini'
                            }
                        })
                    }}>
                        {/*<IonIcon slot="icon-only" icon={context.vodPlayer.visible === 'mini' ? chevronUpCircle : chevronForwardCircle}/>*/}
                        Miniplayer
                    </IonButton>
                    <div className={"float-right footer-text-div"}>
                        <Link className={"no-underline-link underline-hover"} to={"/privacy_policy"}><IonLabel className={"footer-text"}>Privacy
                            Policy</IonLabel></Link>
                        <Link className={"no-underline-link underline-hover"} to={"/legal_details"}><IonLabel className={"footer-text"}>Legal Details</IonLabel></Link>
                    </div>
                </IonToolbar>
            </IonFooter>
        </React.Fragment>
    );
};


export default VodPlayer;
