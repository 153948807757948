// @ts-ignore


import React from "react";
import {normalizeEnumString} from "../utils/StringUtils";
import {RestQueue} from "../api/RestQueue";

export const GAME_LIST: Game[] = [];

export class Game {
    name: string;
    logoUrl: string;
    startpage: string;
    databaseName: string;

    allTournamentsQueue: RestQueue;
    recentMatchesQueue: RestQueue;
    upcomingMatchesQueue: RestQueue;
    allMatchesQueue: RestQueue;
    allTeamsQueue: RestQueue;

    constructor(name: string, logoUrl: string, startpage: string, databaseName: string) {
        this.name = name;
        this.logoUrl = logoUrl;
        this.startpage = startpage;
        this.databaseName = databaseName;
        this.allTournamentsQueue = new RestQueue(this.getNormalizedDatabaseName() + "/tournaments/all");
        this.allMatchesQueue = new RestQueue(this.getNormalizedDatabaseName() + "/matches/all");
        this.recentMatchesQueue = new RestQueue(this.getNormalizedDatabaseName() + "/matches/recent");
        this.upcomingMatchesQueue = new RestQueue(this.getNormalizedDatabaseName() + "/matches/upcoming");
        this.allTeamsQueue = new RestQueue(this.getNormalizedDatabaseName() + "/teams/all");
        GAME_LIST.push(this);
    }

    getGameIconImageTag() {
        return this.getGameLogo("game-icon");
    }

    getGameLogo(clazz: string) {
        return <img className={clazz} src={this.logoUrl}/>;
    }

    getNormalizedDatabaseName() {
        return normalizeEnumString(this.databaseName).replace(" ", "").toLowerCase();
    }

    isSupported() {
        return this === GAME_ROCKET_LEAGUE;
    }
}

export function getGameByStartPage(startpage: string) {
    for (let x = 0; x < GAME_LIST.length; x++) {
        if (GAME_LIST[x].startpage.includes(startpage)) {
            return GAME_LIST[x];
        }
    }

    return null;
}

export function getGameByDatabaseName(name: string) {
    for (let x = 0; x < GAME_LIST.length; x++) {
        if (GAME_LIST[x].databaseName.includes(name)) {
            return GAME_LIST[x];
        }
    }

    return null;
}

export const GAME_ROCKET_LEAGUE = new Game("Rocket League", "https://i.imgur.com/rJDXsLj.png", "/rocketleague", "ROCKET_LEAGUE");
export const GAME_CS = new Game("CS:GO", "https://www.iconarchive.com/download/i105431/papirus-team/papirus-apps/cs.ico", "/csgo", "CS_GO");
export const GAME_DOTA = new Game("Dota 2", "https://www.iconarchive.com/download/i97797/bokehlicia/pacifica/steam-2.ico", "/dota", "DOTA_2");
export const GAME_RAINBOW = new Game("Rainbow Six Siege", "https://i.redd.it/iznunq2m8vgy.png", "/rainbow", "RAINBOW_SIEGE");
export const GAME_LOL = new Game("League of Legends", "https://external-preview.redd.it/FTMkIMnMhnqxCtx-8wlu1wzQaH1UFcA9CaZ3TugXviA.png?auto=webp&s=df74aa3b8d84538bc6a8253a47a7677d903861b0", "/lol", "LOL");