import React, {useState} from 'react';
import {post} from "../api/RestQueue";
import {IonList, IonItem, IonLabel} from "@ionic/react";
import './SearchContent.css'

import {Link,} from 'react-router-dom';

interface SearchProps {
    search: string,
}

export const SearchContent: React.FC<SearchProps> = ({search}) => {
    // @ts-ignore
    const [searchResult, setSearchResult] = useState<any>();

    if (!searchResult || searchResult.query != search) {
        post("search/name", {name: search}, (data) => {
            setSearchResult(data);
        })
    }

    return (
        <IonList className={"no-padding"}>
            {
                searchResult ? searchResult.searchResults.map((result: any) => {
                    return (
                        <SearchItem result={result}/>);
                }) : ""
            }
        </IonList>
    );
};

interface SearchItemProps {
    result: any,
}

const SearchItem: React.FC<SearchItemProps> = ({result}) => {
    let type = "";
    let link = "";

    if(result.tableType === "ESPORT_TEAM") {
        link = "/team/";
        type = "Team";
    } else if(result.tableType === "ESPORT_TOURNAMENT") {
        link = "/tournament/";
        type = "Tournament";
    }

    link += result.id;

    return (
        <Link to={link} className={"no-underline-link underline-hover"}><IonItem color="darkblue-secondary center"><IonLabel text-wrap className="ion-text-wrap">{result.name} <span className={"search-result-type-span"}>({type})</span></IonLabel></IonItem></Link>
    );
};



