import React, {useState} from "react";
import {IonItem, IonLabel} from "@ionic/react";
import './TournamentItem.css';
import {GoToUrlButton} from "../button/GoToUrlButton";
import {getFormattedTournamentDate} from "../../pages/tournament/TournamentPage";
import {Link} from "react-router-dom";
import {fetchMatch, fetchTournament, getDetailData} from "../../api/RestService";
import {formatUnixTimeStampInMillis} from "../../utils/DateUtils";


export interface TournamentItemProps {
    data: any;
}


export const TournamentItem: React.FC<TournamentItemProps> = ({data}) => {
    // @ts-ignore
    const [tournamentData, setTournamentData] = useState<any>();
    let date = "";

    //The data has no detail data but we fetched the tournament data so we can set the data to the fetched data
    if(!data.detailData && tournamentData) {
        data = tournamentData;
    }
    //In some cases the tournament does not come with detail data so we gotta fetch the tournament so we have the detail data
    else if(!data.detailData && !tournamentData) {
        fetchTournament(data.id, (fetchedData) => {
            setTournamentData(fetchedData);
        });
    }

    //If we have detail data present calculate the date
    if (data && data.detailData) {
        let dateObject = formatUnixTimeStampInMillis(parseInt(getDetailData(data, "START_DATE")));
        date = dateObject.getDate() + "." + (dateObject.getMonth() + 1);
    }

    //TODO: Logo etc.
    return (
        <React.Fragment>{data &&
        <IonItem text-wrap color="darkblue-secondary tournament-item" id={"round" + data.id}
                 className="revealedScore transitionScore">
            <span className={"tournament-date"}>{date}</span>
            <Link to={"/tournament/" + data.id} className={"tournament-name-div underline-hover"}>
                <IonLabel text-wrap>{data.name}</IonLabel>
            </Link>
        </IonItem>}</React.Fragment>
    );
};
