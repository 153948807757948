import React, {useState} from 'react';
import {IonContent, IonItem, IonList, IonPage, IonTitle} from '@ionic/react';
import './MatchPage.css';
import '../components/match/Score.css';

import Header from "../components/Header";

import {fetchAllTournaments} from "../api/RestService";
import VodPlayer from "../components/VodPlayer";
import {TournamentItem} from "../components/tournament/TournamentItem";
import {IdPageProps} from "./MatchPage";
import {getGameByStartPage} from "../data/Game";
import {setTabTitle, VOD_PLAYER} from "../App";


export const NotFoundPage: React.FC = () => {

    return (<IonPage>
        {/*<Header/>*/}
        <IonContent>
            <IonList>
                <IonItem color="darkblue center"><IonTitle className="ion-text-center">404 :( We could not find what you are looking for.</IonTitle></IonItem>
            </IonList>
        </IonContent>
        {/*VOD_PLAYER*/}
    </IonPage>)
};
