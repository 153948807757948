import React from 'react';
import {Link,} from 'react-router-dom';
import {getTeamLogoPictureUrl} from "../../api/RestService";

interface TeamLogoProps {
    teamId: number;
    height: number;
}

const TeamLogoLinked: React.FC<TeamLogoProps> = ({teamId, height}) => {
    return (
        <> {<Link to={"/team/" + teamId} ><img height={height + "px"} src={getTeamLogoPictureUrl(teamId)}/></Link>} </>
    );
};

export const TeamLogo: React.FC<TeamLogoProps> = ({teamId, height}) => {
    return (
        <img height={height + "px"} src={getTeamLogoPictureUrl(teamId)}/>
    );
};


export default TeamLogoLinked;
