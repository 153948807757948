import React from 'react';
import './TeamPlayer.css';
import {IonItem, IonLabel} from "@ionic/react";

interface ContainerProps {
    playerId: number;
}

const TeamPlayer: React.FC<ContainerProps> = ({playerId}) => {
    return (
        <IonItem text-wrap color="darkblue-secondary">
            <IonLabel><img src="https://www.kirkham-legal.co.uk/wp-content/uploads/2017/02/profile-placeholder.png" width="7%"></img></IonLabel>
            <IonLabel>Placeholder Name</IonLabel>
        </IonItem>
    );
};

export default TeamPlayer;
