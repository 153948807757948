import React, {useState} from 'react';
import {IonContent, IonItem, IonList, IonPage, IonTitle, IonGrid, IonCol, IonRow} from '@ionic/react';
import './MatchPage.css';
import '../components/match/Score.css';

import Header from "../components/Header";

import {fetchAllTournaments} from "../api/RestService";
import VodPlayer from "../components/VodPlayer";
import {TournamentItem} from "../components/tournament/TournamentItem";
import {IdPageProps} from "./MatchPage";
import {Game, getGameByStartPage} from "../data/Game";
import {setTabTitle, VOD_PLAYER} from "../App";
import {NotFoundPage} from "./NotFoundPage";
import {GameContainerProps, GameTournamentList} from "./LandingPage";
import {MatchesItem} from "./TeamDetail";



export const GameLandingPage: React.FC<IdPageProps> = (game) => {
    const [tournaments, setTournaments] = useState<any>();

    let gameInstance = getGameByStartPage(game.match.params.id);

    if(gameInstance) {
        setTabTitle(gameInstance.name);
    } else {
        return <NotFoundPage/>
    }

    if (!gameInstance?.isSupported()) {
        return (<IonPage>

            <IonContent>
                <IonList>
                    <IonItem color="darkblue center"><IonTitle className="ion-text-center">There is no tracked data for this game available yet!</IonTitle></IonItem>
                </IonList>
            </IonContent>
            {/*VOD_PLAYER*/}
        </IonPage>)
    }


    if (tournaments == null) {
        gameInstance.allTournamentsQueue.fetchData(-1, (data) => {
            setTournaments(data);
        });
    }

    return (
        <IonPage>
            {/*<Header/>*/}
            <IonContent>
                <IonGrid>
                    <IonRow>
                        <IonCol>
                            <IonList>
                                <GameTournamentList game={gameInstance}/>
                            </IonList>
                        </IonCol>
                        <IonCol>
                            <IonList>
                                <GameRecentMatchesItem id={gameInstance.name + "GamePageRecent"} game={gameInstance}/>
                                <GameUpcomingMatchesItem id={gameInstance.name + "GamePageUpcoming"} game={gameInstance}/>
                            </IonList>
                        </IonCol>
                    </IonRow>
                </IonGrid>

            </IonContent>
            {/*VOD_PLAYER*/}
        </IonPage>
    );
};


export interface RecentGamesContainerProps {
    game: Game;
    id: string;
}


export const GameRecentMatchesItem: React.FC<RecentGamesContainerProps> = ({id, game}) => {
    const [recentMatches, setRecentMatches] = useState<any>();

    if(recentMatches == null) {
        game.recentMatchesQueue.fetchData(-1, (data) => {
            setRecentMatches(data);
        });

        return(<React.Fragment></React.Fragment>);
    } else {
        return <MatchesItem id={id} title={"Recent Matches"} recentMatches={recentMatches.content}></MatchesItem>
    }
};

export const GameUpcomingMatchesItem: React.FC<RecentGamesContainerProps> = ({id, game}) => {
    const [recentMatches, setRecentMatches] = useState<any>();

    if(recentMatches == null) {
        game.upcomingMatchesQueue.fetchData(-1, (data) => {
            setRecentMatches(data);
        });

        return(<React.Fragment></React.Fragment>);
    } else {
        return <MatchesItem id={id} title={"Upcoming Matches"}  recentMatches={recentMatches.content}></MatchesItem>
    }
};

