import React, {useState} from 'react';

import './DoubleEliminationPlayoff.css'
import {getWinningTeamFromMatch, IdProps} from "../../match/Match";
import {
    fetchTournamentStage,
    fetchTournamentStageQualifiedTree,
    fetchTournamentStageTree
} from "../../../api/RestService";
import BracketMatch, {BracketMatchQualified} from "../../match/BracketMatch";
import {BracketHeadline, getHeaderStages} from "./BracketHeader";
import {IonCol, IonItem, IonRow} from "@ionic/react";
import {getGrandFinalStageInElimination, getGrandFinalStageInQualifier} from "../../../pages/tournament/TournamentPage";
import {getInitialChildren} from "./DoubleEliminationPlayoff";

/**
 * Qualifier playoff bracket. Handles double elimination and single
 * @param id of the parent stage of the playoff
 * @constructor
 */
export const QualifierPlayoff: React.FC<IdProps> = ({id}) => {
    // @ts-ignore
    const [tournamentTrees, setTournamentTrees] = useState<any[]>();

    const [stageData, setStageData] = useState<any>();

    if (stageData == null || stageData.id != id) {
        fetchTournamentStage(id, data => {
                setStageData(data);

                fetchTournamentStageQualifiedTree(id, trees => {
                    setTournamentTrees(trees);
                });
            }
        );
    }

    return (
        <React.Fragment>
            {stageData && stageData.childrenStages.map((stage: any) => {
                return (
                    <div key={stage.id + "div"} className="wrapper">
                        {
                            tournamentTrees &&
                            <div className={"item"}>
                                {
                                    generateTree(stage, tournamentTrees)
                                }
                            </div>
                        }
                    </div>
                )
            })
            }
        </React.Fragment>
    );
};

function getPreQualifiedMatches(tournamentTrees: any, stageData: any) {
    let matches: any[] = [];

    stageData.childrenStages.map((stage: any) => {
        let finalStage = getGrandFinalStageInElimination(stage);
        matches.push(finalStage.matches);
    });

    return matches;
}

function generateTree(parentStage: any, tournamentTrees: any[]) {
    let children: JSX.Element[] = [];

    let finalStage = getGrandFinalStageInQualifier(parentStage);

    let headerStages = [];

    //Single bracket
    headerStages = getHeaderStages(parentStage, []);

    children.push(
        <div key={finalStage.id}>
            <BracketHeadline ids={headerStages}/>
            {
                finalStage.matches.map((match: any) => {
                    let tree = getFittingTournamentTree(match.id, tournamentTrees);
                    tree.match = match;
                    return (generateSubBracket(tree, finalStage));
                })
            }
        </div>);

    return children;
}

function generateSubBracket(tournamentTree: any, parentStage: any) {
    let children: JSX.Element[] = [];

    let match = tournamentTree.match;

    //The max recursion depth of the top stage
    let maxDepth = tournamentTree.maxDepth - 1;

    let winningTeamId = getWinningTeamFromMatch(match);

    children.push(
        <div key={match.id}>
            <div className="item">
                {/*Qualified Match*/}
                <div className="item-parent">
                    <div className={"team-item-bracket-headline-container"}>
                        {/*<BracketHeader id={stageData.tournamentStage.id}></BracketHeader>*/}
                        <li className="team-item-real team-item"><BracketMatchQualified id={winningTeamId > -1? getWinningTeamFromMatch(match) : -1} matchId={match.id}/></li>
                    </div>
                </div>
                <div className="item-childrens">
                    <div className="item-child">
                        <div className="item">
                            <div className={tournamentTree.childrenNotes.length > 0 ? "item-parent" : ""}>
                                <div className={"team-item-bracket-headline-container"}>
                                    {/*<BracketHeader id={stageData.tournamentStage.id}></BracketHeader>*/}
                                    <li className="team-item-real team-item"><BracketMatch id={match.id}/></li>
                                </div>
                            </div>
                            {tournamentTree.childrenNotes.length > 0 ? <div className="item-childrens">
                                {
                                    getInitialChildren(tournamentTree, maxDepth, parentStage)
                                }
                            </div> : ""}
                        </div>
                    </div>
                </div>
            </div>

        </div>);

    return children;
}

function getFittingTournamentTree(matchId: number, tournamentTrees: any[]) {
    for (let x = 0; x < tournamentTrees.length; x++) {
        if (tournamentTrees[x].match.id == matchId) {
            return tournamentTrees[x];
        }
    }

    return null;
}