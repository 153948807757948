import React from 'react';
import {Route} from 'react-router-dom';
import {IonApp, IonPage, IonRouterOutlet, IonContent} from '@ionic/react';
import {IonReactRouter} from '@ionic/react-router';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
/* Theme variables */
import './theme/variables.css';
import './theme/basic.css';
import MatchPage from "./pages/MatchPage";

import {StateProvider} from './components/StateProvider';
import TeamDetail from "./pages/TeamDetail";
import {TournamentPage} from "./pages/tournament/TournamentPage";
import {GameLandingPage} from "./pages/GamePage";
import {LandingPage} from "./pages/LandingPage";
import VodPlayer from "./components/VodPlayer";
import {PrivacyPolicyPage} from "./pages/legal/PrivacyPolicyPage";
import {LegalDetailsPage} from "./pages/legal/LegalDetailsPage";
import Header from "./components/Header";


export const VOD_PLAYER = (<VodPlayer url={"test"}/>);

export const HEADER = (<Header/>);

const App: React.FC = () => {
    const initialState = {
        theme: {primary: 'green'},
        vodPlayer: {url: '', visible: 'no'},
        header: {name: ''}
    };

    const reducer = (state: any, action: { type: any; newData: any; }) => {
        switch (action.type) {
            case 'changeTheme':
                return {
                    ...state,
                    theme: action.newData
                };
            case 'changeVodUrl':
                let url = action.newData.url;

                if (url.includes('twitch.tv') && !url.includes('player.twitch.tv')) {
                    let lastSlashIndex = url.lastIndexOf('/');
                    let videoURL = url.substring(lastSlashIndex + 1);

                    url = 'https://player.twitch.tv/?parent=' + window.location.hostname + '&video=' + videoURL.replace("?", "&");
                }

                action.newData.url = url;

                console.log(action.newData.url);

                return {
                    ...state,
                    vodPlayer: action.newData
                };
            case 'changeHeader':
                return {
                    ...state,
                    header: {name: action.newData}
                };
            default:
                return state;
        }
    };

    return (
        <IonApp>
            <StateProvider initialState={initialState} reducer={reducer}>
                <IonReactRouter>
                    <IonPage>
                        {HEADER}
                        <IonContent>
                            <IonRouterOutlet>
                                <Route path="/match/:id" component={MatchPage}/>
                                <Route path="/team/:id" component={TeamDetail}/>
                                <Route path="/tournament/:id" component={TournamentPage}/>
                                <Route path="/game/:id" component={GameLandingPage}/>
                                <Route path="/privacy_policy" component={PrivacyPolicyPage}/>
                                <Route path="/legal_details" component={LegalDetailsPage}/>
                                <Route path="/empty" component={EmptyPage}/>
                                <Route exact path="/" component={LandingPage}/>
                                {/*Fallback page*/}
                                <Route render={() => <h2>Page not found</h2>}/>
                            </IonRouterOutlet>
                        </IonContent>


                        {VOD_PLAYER}
                    </IonPage>
                </IonReactRouter>
            </StateProvider>
        </IonApp>);
};

export const EmptyPage: React.FC = () => {
    return (<React.Fragment>

    </React.Fragment>)
};

export default App;

export function isProductionEnv() {
    return !window.location.href.includes("localhost");
}

export function setTabTitle(title: string) {
    document.title = title + " - Evod";
}

export function setDescription(desc: string) {
    document.querySelector('meta[name="description"]')!.setAttribute("content", desc);
}
