
const INVISIBILITY_STATE = {
    STATE_INVISIBLE: 0,
    STATE_SHOWN: 1,
    STATE_PROGRESSED: 2,
    STATE_ALL_VISIBLE: 3,
};

export class VisibilityHandler {
    visibilityMap: Map<number, number> = new Map();
    updateRenderMap: Map<number, any[]> = new Map();
    superHandler : VisibilityHandler | undefined = undefined;

    constructor(superHandler: VisibilityHandler | undefined) {
        this.superHandler = superHandler;
    }

    addRenderMapCall(id: number, call: any) {
        let list : any[] = [];

        if(this.updateRenderMap.has(id)) {
            list = this.updateRenderMap.get(id)!;
        }

        list.push(call);
        this.updateRenderMap.set(id, list);
    }

    getState(id: number): number {
        if(this.visibilityMap.has(id)) {
            return this.visibilityMap.get(id)!;
        } else {
            //TODO: Query database
            if(this.superHandler) {
                return this.superHandler.getState(id);
            }

            return INVISIBILITY_STATE.STATE_INVISIBLE;
        }
    }

    updateRender(id: number) {
        if(this.updateRenderMap.has(id)) {
            let list = this.updateRenderMap.get(id)!;

            //Call all added functions to call
            for(let x = 0; x < list.length; x++) {
                list[x](Math.random());
            }
        }
    }

    setState(id: number, state: number) {
        this.visibilityMap.set(id, state);
    }


    setInvisible(id: number) {
        return this.setState(id, INVISIBILITY_STATE.STATE_INVISIBLE);
    }

    setShown(id: number) {
        return this.setState(id, INVISIBILITY_STATE.STATE_SHOWN);
    }

    setProgressed(id: number) {
        return this.setState(id, INVISIBILITY_STATE.STATE_PROGRESSED);
    }

    setAllVisible(id: number) {
        return this.setState(id, INVISIBILITY_STATE.STATE_ALL_VISIBLE);
    }

    isInvisible(id: number) {
        return this.getState(id) >= INVISIBILITY_STATE.STATE_INVISIBLE;
    }

    isShown(id: number) {
        return this.getState(id) >= INVISIBILITY_STATE.STATE_SHOWN;
    }

    isProgressed(id: number) {
        return this.getState(id) >= INVISIBILITY_STATE.STATE_PROGRESSED;
    }

    isAllVisible(id: number) {
        return this.getState(id) >= INVISIBILITY_STATE.STATE_ALL_VISIBLE;
    }
}

export const VISIBILITY_HANDLER = {
    MATCH: new VisibilityHandler(undefined),
    ROUND: new VisibilityHandler(undefined),
    WILDCARD_MATCHES: new VisibilityHandler(undefined),
};

//So we can have the dependency
VISIBILITY_HANDLER.ROUND = new VisibilityHandler(VISIBILITY_HANDLER.MATCH);
