import React, {useState} from 'react';
import {
    IonButton,
    IonButtons,
    IonHeader,
    IonIcon,
    IonRouterOutlet,
    IonTitle,
    IonToolbar,
    IonSearchbar,
    IonItem
} from '@ionic/react';

import {Link,} from 'react-router-dom';
import './Header.css'

import {personCircle, search} from "ionicons/icons";
import {useStateValue} from "./StateProvider";
import {GAME_LIST, GAME_ROCKET_LEAGUE} from "../data/Game";
import {post} from "../api/RestQueue";
import {SearchContent} from "./SearchContent";


let setShowDropdownOuter = (b: boolean) => {
};
let currentSelectedGame = GAME_ROCKET_LEAGUE;

const Header: React.FC = () => {
    const [showDropdown, setShowDropdown] = useState(false);

    const [searchText, setSearchText] = useState("");

    // @ts-ignore
    const [context, dispatch] = useStateValue();
    setShowDropdownOuter = setShowDropdown;
    return (
        <IonHeader>
            <div className={"header-div toolbar-searchbar ion-color ion-color-dark-medium md in-toolbar hydrated"} color="dark-medium">
                <IonButtons slot="start">
                    <Link to={"/"}>
                        <div className={"logo-button"}><img src="/assets/icon/favicon.png"
                                                            className={"game-icon logo"}/></div>
                    </Link>
                    <div className="dropdown">
                        <button className="dropbtn" onClick={() => {
                            setShowDropdown(!showDropdown);
                        }}>

                            {currentSelectedGame.getGameIconImageTag()}
                            <span className="game-icon-span">{currentSelectedGame.name}</span>
                            <i className="fa fa-caret-down"></i>
                        </button>

                        {<div id="dropdown-content" className={"dropdown-content " + (showDropdown ? "" : "hidden")}>
                            {
                                GAME_LIST.map((game) => {
                                    return (<Link key={game.name + "GameEntry"} to={"/game" + game.startpage}
                                                  onClick={() => setCurrentGame(game)}>{game.getGameIconImageTag()}<span
                                        className="game-icon-span">{game.name}</span></Link>);
                                })
                            }
                        </div>}
                    </div>
                </IonButtons>
                <IonButtons slot="secondary">
                    {/*<IonButton color="danger" fill="outline">
                            Edit
                            <IonIcon slot="end" icon={create}/>
                        </IonButton>*/}
                        <div>
                            <IonSearchbar color={"darkblue"} value={searchText} onIonChange={(e) => {
                                setSearchText(e.detail.value!)
                            }}>
                            </IonSearchbar>
                            <div className={"search-results" + (searchText && searchText.length > 2 ? "" : "hidden")}>
                                {
                                    searchText && searchText.length > 2 ? <SearchContent search={searchText}/> : ""
                                }
                            </div>

                        </div>

                    {/*<IonButton>
                        <IonIcon slot="icon-only" icon={personCircle}/>
                    </IonButton>*/}
                    <IonButton onClick={() => {
                        post("search/name", {name: "vitality"}, (data => {
                            console.log(data);
                        }))
                    }}>
                    </IonButton>
                </IonButtons>
            </div>
        </IonHeader>
    );
};

function setCurrentGame(game: any) {
    currentSelectedGame = game;
    disableDropdown();
}

function disableDropdown() {
    setShowDropdownOuter(false);
}

// Close the dropdown menu if the user clicks outside of it
window.onclick = function (event: any) {
    if (!event.target.matches('.game-icon-span') && !event.target.matches('.dropbtn')) {
        disableDropdown();
    }
};


export default Header;
