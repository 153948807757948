// @ts-ignore
import React from 'react';
import {IonButton} from '@ionic/react';
import {getDetailData} from "../api/RestService";
import {PlayVodProps} from "./button/PlayVodButton";

export const LiquipediaButton: React.FC<PlayVodProps> = ({vods}) => {
    let url:string = "";

    if(vods) {
        url = getDetailData(vods, "LIQUIDPEDIA_LINK");
    }

    if(!url || url.length === 0) {
        return (<React.Fragment></React.Fragment>);
    }

    return (
        <IonButton onClick={() => {
                window.open(vods && getDetailData(vods, "LIQUIDPEDIA_LINK"));
        }}>
            Liquipedia
        </IonButton>
    );
};