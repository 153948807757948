export function normalizeEnumString(str: string) {
    if(!str) {
        return "";
    }

    let splitStr = str.toLowerCase().split('_');
    for (let i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }

    // Directly return the joined string
    return splitStr.join(' ');
}

export function removeMatchingCharsFromStart(original: string, toRemove: string) {
    for(let x = 0; x < toRemove.length && x < original.length; x++) {
        if(original[x] !== toRemove[x]) {
            return original.substr(x);
        }
        //To remove matches the beginning of original but now toRemove has ended so we return the rest of original
        else if(x === toRemove.length - 1) {
            return original.substr(++x);
        }
    }

    return "";
}