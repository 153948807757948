import React from 'react';

import {IonButton, IonIcon} from "@ionic/react";
import {playOutline} from "ionicons/icons";
import {useStateValue} from "../StateProvider";


export interface PlayVodProps {
    vods: any;
}

export const PlayVodButton: React.FC<PlayVodProps> = ({vods}) => {
    // @ts-ignore
    const [context, dispatch] = useStateValue();

    if(!vods || vods.length == 0) {
        return(<React.Fragment/>)
    }

    return (<IonButton color="dark" onClick={() => {
            if(vods.length === 1) {
                dispatch({
                    type: 'changeVodUrl',
                    newData: {url: vods[0].url, visible: 'big'}
                })
            } else {
                //TODO: Show vod selection
            }
        }}>
            <IonIcon slot="icon-only" icon={playOutline}/>
        </IonButton>
    )
};

