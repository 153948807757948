import React, {useState} from 'react';
import {
    IonCol,
    IonContent,
    IonGrid,
    IonItem,
    IonLabel,
    IonList,
    IonPage,
    IonRow,
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonIcon,
    IonBadge,
    IonRouterOutlet, IonTitle,
} from '@ionic/react';
import {Route, Redirect} from 'react-router';
import {IonReactRouter} from '@ionic/react-router';
import './LandingPage.css';


import Header from "../components/Header";

import VodPlayer from "../components/VodPlayer";

import {Link} from "react-router-dom";
import {Game, GAME_LIST} from "../data/Game";
import {TournamentItem} from "../components/tournament/TournamentItem";
import {setTabTitle, VOD_PLAYER} from "../App";
import {calendar, informationCircle, location, map, people, personCircle} from "ionicons/icons";
import {TournamentPage} from "./tournament/TournamentPage";
import {GameRecentMatchesItem, GameUpcomingMatchesItem} from "./GamePage";


export const LandingPage: React.FC = () => {
    //Means no sub path, otherwise it would keep triggering even we are on different pages
    if (window.location.pathname.length === 1) {
        setTabTitle("Home");
    }

    return (
            <IonPage>
                {/*<Header/>*/}
                <IonContent>
                    <IonGrid className={"no-padding"}>
                        <IonRow>
                            {
                                GAME_LIST.map((game) => {
                                    return (
                                        <IonCol size={"4"} key={game.name}>
                                            <IonList>
                                                <IonItem color={"darkblue"}>
                                                    <Link to={"/game" + game.startpage} className={"no-underline-link"}>
                                                        {game.getGameLogo("game-icon")}
                                                        <span className="game-span underline-hover">{game.name}</span>
                                                    </Link>
                                                </IonItem>

                                                {game.isSupported()? <React.Fragment>
                                                    <GameTournamentList game={game}/>
                                                    <GameRecentMatchesItem id={game.name + "LandingPageRecent"} game={game}/>
                                                    <GameUpcomingMatchesItem id={game.name + "LandingPageUpcoming"} game={game}/>
                                                </React.Fragment> : <IonItem color={"darkblue-secondary"}><IonLabel>No tracked data available yet</IonLabel></IonItem> }

                                            </IonList>
                                        </IonCol>
                                    );
                                })
                            }
                        </IonRow>
                    </IonGrid>
                </IonContent>
                {/*VOD_PLAYER*/}
            </IonPage>
    );
};


export interface GameContainerProps {
    game: Game;
}


export const GameTournamentList: React.FC<GameContainerProps> = ({game}) => {
    const [tournaments, setTournaments] = useState<any>();

    if (!game.isSupported()) {
        return (<IonItem color={"darkblue-secondary"}><IonLabel>No tracked data available yet</IonLabel></IonItem>);
    } else if (!tournaments) {
        game.allTournamentsQueue.fetchData(-1, (data) => {
            setTournaments(data);
        });
    }

    return (
        <React.Fragment>
            <IonItem color="darkblue center"><IonLabel className="ion-text-center">Recent Tournaments</IonLabel></IonItem>
            {
                tournaments && tournaments.content.map((tournament: any) => {
                    return (
                        <TournamentItem key={tournament.id + "Tournament"} data={tournament}/>);
                })
            }
        </React.Fragment>
    );
};
