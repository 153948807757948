import React from 'react';

import {IonButton, IonIcon} from "@ionic/react";
import {arrowForward} from "ionicons/icons";
import {VISIBILITY_HANDLER} from "../../user/VisibilityHandler";
import {IdProps} from "../match/Match";


interface UrlProps {
    url: string;
    //If I only knew what type this must be for it to fit the function
    onClick: any;
}

export const GoToUrlButton: React.FC<UrlProps> = ({url, onClick}) => {
    return (<IonButton color="dark" routerLink={url} onClick={onClick}>
            <IonIcon icon={arrowForward}/>
        </IonButton>
    )
};

export const GoToMatchButton: React.FC<IdProps> = ({id}) => {
    return(<GoToUrlButton onClick={() => {
        if (!VISIBILITY_HANDLER.MATCH.isShown(id)) {
            VISIBILITY_HANDLER.MATCH.setShown(id);
        }
    }} url={"/match/" + id}/>)
};



