import React, {useState} from 'react';

import './DoubleEliminationPlayoff.css'
import {IdProps} from "../../match/Match";
import {fetchTournamentStage, fetchTournamentStageTree} from "../../../api/RestService";
import BracketMatch from "../../match/BracketMatch";
import {BracketHeadline, getHeaderStages} from "./BracketHeader";

/**
 * Double elimination playoff bracket but also can handle single elimination brackets
 * @param id of the last stage/grand final to fetch the tournament tree from
 * @constructor
 */
export const DoubleEliminationPlayoff: React.FC<IdProps> = ({id}) => {
    // @ts-ignore
    const [tournamentTree, setTournamentTree] = useState<any>();
    const [parentStage, setParentStage] = useState<any>();

    if (tournamentTree == null || tournamentTree.tournamentStage.id !== id) {
        fetchTournamentStageTree(id, treeData => {
                setTournamentTree(treeData);

                fetchTournamentStage(treeData.parentStage.id, stageData => {
                        setParentStage(stageData);
                    }
                );
            }
        );
    }

    return (
        <div className="wrapper">
            {
                tournamentTree && parentStage &&
                <div className={"item"}>
                    {
                        generateSubBracket(tournamentTree, parentStage)
                    }
                </div>
            }
        </div>
    );
};

function generateSubBracket(tournamentTree: any, parentStage: any) {
    let children: JSX.Element[] = [];

    let match = tournamentTree.match;

    //The max recursion depth of the top stage
    let maxDepth = tournamentTree.maxDepth  - 1;

    let topStage = tournamentTree.childrenNotes[0].maxDepth;

    let headerStages = [];

    //Multiple brackets
    if(parentStage.childrenStages[0].childrenStages.length > 0) {
        headerStages = getHeaderStages(parentStage.childrenStages[0], []);

        for(let x = topStage; x < maxDepth; x++) {
            headerStages.push(0 - x);
        }

        headerStages.push(parentStage.childrenStages[parentStage.childrenStages.length - 1].id);
    } else {
        //Single bracket
        headerStages = getHeaderStages(parentStage, []);
    }

    children.push(
        <div key={match.id}>
            <BracketHeadline ids={headerStages}/>
            <div className="item">
                <div className={tournamentTree.childrenNotes.length > 0 ? "item-parent" : ""}>
                    <div className={"team-item-bracket-headline-container"}>
                        {/*<BracketHeader id={stageData.tournamentStage.id}></BracketHeader>*/}
                        <li className="team-item-real team-item"><BracketMatch id={match.id}/></li>
                    </div>
                </div>
                {tournamentTree.childrenNotes.length > 0 ? <div className="item-childrens">
                    {
                        getInitialChildren(tournamentTree, maxDepth, parentStage)
                    }
                </div> : ""}
            </div>
        </div>);

    return children;
}

export function getInitialChildren(tournamentTree: any, maxDepth: number, parentStage: any) {
    let childrenNotes = tournamentTree.childrenNotes;
    let children = [];

    for (let x = 0; x < childrenNotes.length; x++) {
        let child = childrenNotes[x];

        let id =  parentStage.childrenStages && parentStage.childrenStages.length > x? parentStage.childrenStages[x].id : child.id;

        children.push(<React.Fragment key={id + parentStage.id}>
            {
                //We have the top headline placed in the previous function, so we only need this headline for the below branches
                x > 0 && parentStage.childrenStages[x] && parentStage.childrenStages[x].childrenStages.length > 0? <BracketHeadline ids={getHeaderStages(parentStage.childrenStages[x], [])}/> : ""}
            {
                generatePlaceholder(maxDepth - child.maxDepth, child)
            }
        </React.Fragment>)
    }

    return children;
}

function generateSubBracket2(tournamentTree: any, first: boolean) {
    let children: JSX.Element[] = [];

    let stage = tournamentTree;
    let match = tournamentTree.match;
    children.push(
        <div key={match.id} className={first ? "" : "item-child"}>
            <div className="item">
                <div className={tournamentTree.childrenNotes.length > 0 ? "item-parent" : ""}>
                    <div className={"team-item-bracket-headline-container"}>
                        {/*<BracketHeader id={stageData.tournamentStage.id}></BracketHeader>*/}
                        <li className="team-item-real team-item">
                            <BracketMatch id={match.id}/></li>
                    </div>
                </div>
                {tournamentTree.childrenNotes.length > 0 ? <div className="item-childrens">
                    {
                        tournamentTree.childrenNotes.map((child: any) => {
                            return (
                                generateSubBracket2(child, false));
                        })
                    }
                </div> : ""}
            </div>
        </div>);

    return children;
}

function generatePlaceholder(remaining: number, tournamentTree: any) {
    let children: JSX.Element[] = [];

    //If we need to generate more placeholders create them now
    if (remaining > 0) {
        children.push(
            <div key={remaining} className={"item-child"}>
                <div className="item">
                    <div className={remaining > 0 ? "item-parent" : ""}>
                        <p className="team-item-placeholder"></p>
                    </div>
                    <div className="item-childrens">
                        {
                            generatePlaceholder(--remaining, tournamentTree)
                        }
                    </div>
                </div>
            </div>
        );
    } else {
        return generateSubBracket2(tournamentTree, false);
    }

    return children;
}