import React, {useState} from 'react';

import {IonItem, IonTitle} from "@ionic/react";

import Match, {IdProps, TwoMatch} from "../../match/Match";
import {fetchTournamentStage} from "../../../api/RestService";


const GroupStage: React.FC<IdProps> = ({id}) => {
    // @ts-ignore
    const [stageData, setStageData] = useState<any>();

    if (stageData == null || stageData.id != id) {
        fetchTournamentStage(id, data => {
                setStageData(data);
            }
        );
    }
    
    return (
        <React.Fragment>
            {
                stageData &&
                <React.Fragment>
                    <IonItem color="darkblue center"><IonTitle className="ion-text-center">{stageData.name}</IonTitle></IonItem>
                    {
                        stageData.matches.map((match: any) => {
                            return (<TwoMatch key={match.id + "GroupStageMatch"} id={match.id}></TwoMatch>);
                        })}
                </React.Fragment>}
        </React.Fragment>
    );
};

export default GroupStage;