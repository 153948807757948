import React, {useState} from 'react';

import {IonButton, IonIcon, IonItem, IonLabel, IonTitle} from "@ionic/react";

import './BracketHeader.css'
import {getFirstRoundOfMatchWithVod, IdProps} from "../../match/Match";
import {fetchTournamentStage} from "../../../api/RestService";
import {ellipsisVerticalOutline} from "ionicons/icons";
import {hasTBDTeam, isNotDeterminedYet, isTBD, toggleBracketInfo} from "../../match/BracketMatch";
import {VISIBILITY_HANDLER} from "../../../user/VisibilityHandler";
import {PlayVodButton} from "../../button/PlayVodButton";
import {GoToMatchButton} from "../../button/GoToUrlButton";

export interface BracketHeaderProps {
    ids: number[];
}


export const BracketHeader: React.FC<IdProps> = ({id}) => {
    // @ts-ignore
    const [stageData, setStageData] = useState<any>();


    let name = "";

    //Placeholder id
    if (id < 0) {
        name = "";
    } else if (stageData == null || stageData.id !== id) {
        fetchTournamentStage(id, stageData => {
                setStageData(stageData);
            }
        );

        return (<React.Fragment/>);
    } else {
        name = stageData.name;
    }

    let qualified = stageData?.tournamentStageCategory === 'QUALIFIED';

    return (
        <a className={"team-item-bracket-headline"} color="darkblue">
            <IonTitle className={"ion-text-center color-contrast " + (qualified? "bracket-headline-title-no-best-of" : "bracket-headline-title")}>{name}
                {id >= 0 && !qualified? <p className={"best-of-text"}>Best of {stageData && stageData.bestOf}</p> : ""}
            </IonTitle>
            {id >= 0 ?  <div className="dropdown-div-match-detail dropdown-div-bracket-detail">
                {!qualified && <button className={"detail-bracket-header-button detail-button"} onClick={() => {
                    toggleBracketInfo(stageData.id + "Stage");
                }}>

                    <IonIcon className={"bracket-info-icon"} slot="icon-only" icon={ellipsisVerticalOutline}/>
                </button>}
                {stageData && <div id={stageData.id + "StageDetail"} className={"match-detail-dropdown bracket-detail-dropdown hidden"}>
                    <IonButton color="dark" onClick={() => {
                        for(let x = 0; x < stageData.matches.length; x++) {
                            if(!isTBD(stageData.matches[x])) {
                                VISIBILITY_HANDLER.MATCH.setShown(stageData.matches[x].id);
                                VISIBILITY_HANDLER.MATCH.updateRender(stageData.matches[x].id);
                            }
                        }
                        toggleBracketInfo(stageData.id + "Stage");
                    }}>Show Matches</IonButton>
                    <br/>

                    {<IonButton color="dark" onClick={() => {
                        for(let x = 0; x < stageData.matches.length; x++) {
                            if(!hasTBDTeam(stageData.matches[x]) && !isNotDeterminedYet(stageData.matches[x])) {
                                VISIBILITY_HANDLER.MATCH.setAllVisible(stageData.matches[x].id);
                                VISIBILITY_HANDLER.MATCH.updateRender(stageData.matches[x].id);
                            }
                            //If we can't show scores at least show the match maybe?
                            else if(!isTBD(stageData.matches[x])) {
                                VISIBILITY_HANDLER.MATCH.setShown(stageData.matches[x].id);
                                VISIBILITY_HANDLER.MATCH.updateRender(stageData.matches[x].id);
                            }
                        }
                        toggleBracketInfo(stageData.id + "Stage");
                    }}>Reveal Scores</IonButton>}
                </div>}
            </div> : ""}
        </a>
    );
};

export const BracketHeadline: React.FC<BracketHeaderProps> = ({ids}) => {
    return (
        <div className="tournament-bracket-header-container">
            {ids.map((stage: any) => {
                return (
                    <BracketHeader key={stage} id={stage}></BracketHeader>
                )
            })}
        </div>
    );
};


export function getHeaderStages(stage: any, additionalStages: [any?]) {
    let stages = [];

    if (!stage.childrenStages) {
        stages.push(stage.id);
    } else {
        let childlessStages = getChildlessStages(stage);

        for (let x = 0; x < childlessStages.length; x++) {
            let stageData = childlessStages[x];
            stages.push(stageData.id);
        }
    }

    if (additionalStages !== undefined && additionalStages !== null) {
        for (let x = 0; x < additionalStages.length; x++) {
            stages.push(additionalStages[x].id);
        }
    }

    return stages;
}

/**
 * Gets the childless children stages of the given stage
 * @param stage A list of the very bottom stages that have no more children, only the given stage if the stage has no children
 */
function getChildlessStages(stage: any): any[] {
    let stages = [];

    if (stage.childrenStages.length > 0) {
        for (let x = 0; x < stage.childrenStages.length; x++) {
            //Recursive call
            let childlessStages = getChildlessStages(stage.childrenStages[x]);

            //Add all returned stages
            for (let y = 0; y < childlessStages.length; y++) {
                stages.push(childlessStages[y]);
            }
        }
    } else {
        //Stage has no children, return stage itself in array
        stages.push(stage);
    }

    return stages;
}

function getGrandFinalStage(stageData: any) {
    /*for(let x = 0; x < stageData.childrenStages.length; x++) {
        let stage = stageData.childrenStages[x];

        if(stage.name.indexOf("Grand Finals") > -1) {
            return
        }
    }*/

    return stageData.childrenStages[stageData.childrenStages.length - 1];
}
