import React, {useState} from "react";
import {useStateValue} from "../StateProvider";
import {fetchTournament} from "../../api/RestService";
import {IonButton, IonItem, IonLabel, IonRow} from "@ionic/react";
import {GoToUrlButton} from "../button/GoToUrlButton";
import {IdProps} from "../match/Match";
import {setTabTitle} from "../../App";
import {removeMatchingCharsFromStart} from "../../utils/StringUtils";


export const TournamentTabBar: React.FC<IdProps> = ({id}) => {
    const [tournamentData, setTournamentData] = useState<any>();


    if (!tournamentData || tournamentData.id !== id) {
        fetchTournament(id, data => {
                setTournamentData(data);
            }
        );
    }


    //TODO: Logo, date etc.
    return (
        <React.Fragment>{
            tournamentData && tournamentData.childrenTournaments.map((child: any) => {
                return (<IonButton routerLink={"/tournament/" + child.id}>
                        {removeMatchingCharsFromStart(child.name, tournamentData.name).replace(":", "").replace("-", "").trim()}
                    </IonButton>)
            })
        }</React.Fragment>
    );
};

